import { ReactNode, createContext, useContext, useState } from 'react';
import useSettingsContext from './SettingsContext';

type facingMode = 'user' | 'environment';

type selectedDevicesContextType = {
    setCameraId: (cameraId: string | null) => void;
    cameraId: string | null;
    setMicrophoneId: (microphoneId: string | null) => void;
    microphoneId: string | null;
    setFacingMode: (facingMode: string) => void;
    facingMode: facingMode;
};

const SelectedDevicesContext = createContext<selectedDevicesContextType>({
    setCameraId: (cameraId) => {},
    cameraId: null,
    setMicrophoneId: (microphoneId) => {},
    microphoneId: null,
    setFacingMode: (facingMode) => {},
    facingMode: 'user',
});

export const SelectedDevicesContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const settings = useSettingsContext();

    const [selectedCamera, setSelectedCamera] = useState<string | null>(
        settings?.get('cameraId') as string
    );
    const [selectedMicrophone, setSelectedMicrophone] = useState<string | null>(
        settings?.get('microphoneId') as string
    );
    const [facingMode, setFacingMode] = useState<string>(
        (settings?.get('facingMode') as string) || 'user'
    );

    return (
        <SelectedDevicesContext.Provider
            value={{
                setCameraId: (cameraId) => {
                    setSelectedCamera(cameraId);
                },
                setMicrophoneId: (microphoneId) => {
                    setSelectedMicrophone(microphoneId);
                },
                setFacingMode(facingMode) {
                    setFacingMode(facingMode);
                },
                cameraId: selectedCamera,
                microphoneId: selectedMicrophone,
                facingMode: facingMode as facingMode,
            }}
        >
            {children}
        </SelectedDevicesContext.Provider>
    );
};

const useSelectedDevices = () => {
    const selectedDevicesContext = useContext(SelectedDevicesContext);

    return selectedDevicesContext;
};
export default useSelectedDevices;
