import {
    Box,
    Button,
    Card,
    CircularProgress,
    Typography,
    useTheme,
} from '@mui/material';
import { ChatMessageType } from '../wsClient/WsClient.types';
import DownloadIcon from '@mui/icons-material/Download';
import apiClient from '../apiClient/ApiClient';
import { useState } from 'react';

const ChatMessage = ({
    chatMessage,
    userDisplayNames,
    isYourOwnMessage,
    roomId,
}: {
    chatMessage: ChatMessageType;
    userDisplayNames: { [key: string]: string };
    isYourOwnMessage: boolean;
    roomId: string;
}) => {
    const theme = useTheme();
    const [isDownloading, setIsDownloading] = useState(false);

    return (
        <Card
            sx={{
                padding: 1,
                mb: 1,
                backgroundColor: isYourOwnMessage
                    ? ''
                    : theme.palette.info.main,
            }}
        >
            <Box>
                <Typography variant="caption" color="gray">
                    {userDisplayNames[chatMessage.sender]}
                </Typography>
            </Box>
            {chatMessage.content_type === 'text/chat' && (
                <Box>
                    <Typography sx={{whiteSpace: 'pre-line'}}>{chatMessage.content}</Typography>
                </Box>
            )}
            {chatMessage.content_type !== 'text/chat' && (
                <Box>
                    <Button
                        endIcon={<DownloadIcon />}
                        disabled={isDownloading}
                        onClick={async () => {
                            const downloadObject = document.createElement('a');
                            setIsDownloading(true);

                            const blob = await apiClient.getFileContents(
                                roomId,
                                chatMessage.content
                            );

                            if (!blob) {
                                return;
                            }

                            const payloadUrl = window.URL.createObjectURL(blob);

                            downloadObject.href = payloadUrl;
                            downloadObject.target = '_blank';
                            downloadObject.download =
                                chatMessage.filename ||
                                `attachment.${chatMessage.content_type
                                    .split('/')
                                    .at(-1)}`;
                            downloadObject.click();

                            setIsDownloading(false);
                        }}
                    >
                        {chatMessage.filename || 'attachment'}
                    </Button>
                    {isDownloading && <CircularProgress size="0.87rem" />}
                </Box>
            )}
        </Card>
    );
};
export default ChatMessage;
