import {
    InitialSyncPayloadData,
    InitialSyncRequest,
    InitialSyncResponse,
    LoginResponse,
    SaveWhiteboardStateRequest,
    SyncRequest,
    SyncResponse,
    SyncResponseRaw,
    WsApiResponse,
    onLoginHandler,
    onOpenHandler,
    onSyncHandler,
    onWhiteboardInitialSyncRequest,
    onWhiteboardInitialSyncResponse,
} from './WhiteboardClient.types';
import { compressToBase64, decompressFromBase64 } from 'lz-string';

const wsUrl =
    process.env.NODE_ENV === 'production'
        ? 'wss://ewizyta.igabinet.com:8100/whiteboard'
        : 'wss://localhost:8100/whiteboard';

class WhiteboardClient {
    wsConn: WebSocket;
    private onOpenHandler: onOpenHandler | undefined;
    private onSyncHandler: onSyncHandler | undefined;
    private onLoginHandler: onLoginHandler | undefined;
    private onWhiteboardInitialSyncResponse:
        | onWhiteboardInitialSyncResponse
        | undefined;
    private onWhiteboardInitalSyncRequest:
        | onWhiteboardInitialSyncRequest
        | undefined;

    constructor({
        onOpenHandler,
        onSyncHandler,
        onLoginHandler,
        onWhiteboardInitialSyncResponse,
        onWhiteboardInitalSyncRequest,
    }: {
        onOpenHandler?: onOpenHandler;
        onSyncHandler?: onSyncHandler;
        onLoginHandler?: onLoginHandler;
        onWhiteboardInitialSyncResponse?: onWhiteboardInitialSyncResponse;
        onWhiteboardInitalSyncRequest?: onWhiteboardInitialSyncRequest;
    }) {
        this.wsConn = new WebSocket(wsUrl);
        this.onOpenHandler = onOpenHandler;
        this.onSyncHandler = onSyncHandler;
        this.onLoginHandler = onLoginHandler;
        this.onWhiteboardInitialSyncResponse = onWhiteboardInitialSyncResponse;
        this.onWhiteboardInitalSyncRequest = onWhiteboardInitalSyncRequest;

        if (this.onOpenHandler) {
            this.wsConn.addEventListener('open', this.onOpenHandler);
        }

        this.wsConn.addEventListener('message', async (event) => {
            try {
                const data: WsApiResponse<
                    | SyncResponse
                    | InitialSyncResponse
                    | InitialSyncRequest
                    | LoginResponse
                    | SyncResponseRaw
                > = JSON.parse(event.data);

                if (data.code !== 0) {
                    throw new Error('api error recieved code: ' + data.code);
                }

                switch (data.action) {
                    case 'syncWhiteboard': {
                        if (this.onSyncHandler) {
                            // we use lz-string compression for steps to prevent the browser from splitting
                            // a JSON message into multiple frames over WS connection
                            // in current architecture we expect 1 message = 1 frame
                            const payloadDecompressed = decompressFromBase64(
                                (data.data as SyncResponseRaw).steps
                            );
                            const payloadParsed = await JSON.parse(
                                payloadDecompressed
                            );

                            const dataObject: SyncResponse =
                                data.data as SyncResponse;

                            const remappedData: SyncResponse = {
                                sender: dataObject.sender,
                                steps: payloadParsed,
                            };

                            this.onSyncHandler(remappedData);
                        }
                        break;
                    }
                    case 'loginWhiteboard': {
                        if (this.onLoginHandler) {
                            this.onLoginHandler(data.data as LoginResponse);
                        }
                        break;
                    }
                    case 'whiteboardInitialSyncResponse': {
                        if (this.onWhiteboardInitialSyncResponse) {
                            this.onWhiteboardInitialSyncResponse(
                                data.data as InitialSyncResponse
                            );
                        }
                        break;
                    }
                    case 'whiteboardInitialSyncRequest': {
                        if (this.onWhiteboardInitalSyncRequest) {
                            this.onWhiteboardInitalSyncRequest(
                                data.data as InitialSyncRequest
                            );
                        }
                        break;
                    }
                }
            } catch (e) {
                console.warn(e);
                return;
            }
        });
    }

    public login(selfId: string, whiteboardId: string) {
        const request = {
            action: 'loginWhiteboard',
            payload: {
                uuid: selfId,
                whiteboard_id: whiteboardId,
            },
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public sendSync(payload: Array<SyncRequest>) {
        // implement compression to prevent browser from splitting JSON messages into multiple frames
        const payloadJson = JSON.stringify(payload);
        const payloadCompressed = compressToBase64(payloadJson);

        const request = {
            action: 'syncWhiteboard',
            payload: payloadCompressed,
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public requestInitialSync() {
        const request = {
            action: 'whiteboardInitialSyncRequest',
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public sendInitialSyncPayload(data: InitialSyncPayloadData) {
        const request = {
            action: 'whiteboardInitialSyncResponse',
            payload: data,
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public saveWhiteboardState(data: SaveWhiteboardStateRequest) {
        const request = {
            action: 'whiteboardSaveState',
            payload: data,
        };

        this.wsConn.send(JSON.stringify(request));
    }
}

export default WhiteboardClient;
