/* eslint-disable react-hooks/exhaustive-deps*/
import {
    Box,
    IconButton,
    Paper,
    Typography,
    useTheme,
    useMediaQuery,
    Tooltip,
    Badge,
} from '@mui/material';
import DefaultLayout from '../layouts/DefaultLayout';
import MicIcon from '@mui/icons-material/Mic';
import { useEffect, useMemo, useRef, useState } from 'react';
import useSettingsContext from '../contexts/SettingsContext';
import MicOffIcon from '@mui/icons-material/MicOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import VideoStream from './VideoStream';
import WsClient from '../wsClient/WsClient';
import { UUID } from 'crypto';
import useLanguagepack from '../languagepack/Languagepack';
import CameraOffIcon from '@mui/icons-material/NoPhotography';
import CameraOnIcon from '@mui/icons-material/CameraAlt';
import {
    ChatMessageType,
    MetaRequest,
    OfferResponse,
} from '../wsClient/WsClient.types';
import ChatIcon from '@mui/icons-material/Chat';
import ChatDrawer from './ChatDrawer';
import { callRoomDataType } from '../apiClient/ApiClient.types';
import useSelfUserUuid from '../contexts/SelfUserUuidContext';
import CastOutlinedIcon from '@mui/icons-material/CastOutlined';
import ConfirmScreenshare from './ConfirmScreenshare';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { isDesktop } from 'react-device-detect';

// igabinet stun servers
const rtcConfig = {
    iceServers: [
        {
            urls: 'stun:turn.igabinet.com',
            credential: 'igabinet1',
            username: 'igabinet1',
        },
        {
            urls: 'turn:turn.igabinet.com',
            credential: 'igabinet1',
            username: 'igabinet1',
        },
    ],
};

const CallRoom = ({
    localMediaStream,
    roomData,
}: {
    localMediaStream: MediaStream;
    roomData: callRoomDataType;
}) => {
    const settings = useSettingsContext();
    const languagePack = useLanguagepack();
    const selfUserId = useSelfUserUuid();
    const theme = useTheme();

    // I know this is a lot of state vars but, at least every action / event has it's own object / queue
    // so it's harder to break everything

    const [wsClient, setWsClient] = useState<WsClient | undefined>();

    const [microphoneMuted, setMicrophoneMuted] = useState<boolean>(
        settings?.get('microphone_muted') as boolean
    );
    const [cameraOff, setCameraOff] = useState<boolean>(
        settings?.get('camera_off') as boolean
    );
    const [connectedDevices, setConnectedDevices] = useState<{
        [peerUUID: string]: {
            [streamUUID: string]: MediaStream;
        };
    }>({});

    const [userDisplayNames, setUserDisplayNames] = useState({
        [selfUserId]: languagePack.you,
    });

    const [userMeta, setUserMeta] = useState<{ [key: UUID]: MetaRequest }>({});
    const [activeScreenshares, setActiveScreenshares] = useState<Array<UUID>>(
        []
    );

    const [rtcConnections, setRtcConnections] = useState<{
        [key: string]: RTCPeerConnection;
    }>({});

    // following 5 queues are necessary bc of how state works. if they were handled in a simple callback we would not
    // get the most recent state. This could be changed to useCallback but it also would require state ensuring
    // this is the simplest most robust solution
    const [answearQueue, setAnswerQueue] = useState<
        Array<{ peer: string; answer: RTCSessionDescription }>
    >([]);

    const [offerQueue, setOfferQueue] = useState<Array<OfferResponse>>([]);

    const [iceQueue, setIceQueue] = useState<
        Array<{ peer: string; candidate: RTCIceCandidate }>
    >([]);

    const [chatSyncRequestQueue, setChatSyncRequestQueue] = useState<
        Array<UUID>
    >([]);

    const [chatMessages, setChatMessages] = useState<{
        [key: string]: ChatMessageType;
    }>({});

    // This queue is kinda braindead (acually it's not even a queue), it's a state notifying
    // the handler if any part of the program wants to perform a meta sync
    const [metaRequestsQueue, setMetaRequestQueue] = useState<boolean>(false);

    const [unreadChatCount, setUnreadChatCount] = useState(0);

    const [chatDrawerOpen, setChatDrawerOpen] = useState(false);

    // on state change re-run effect creating ws client
    const [reconnectToWs, setReconnectToWs] = useState([]);

    const [screenshareMediastream, setScreenshareMediastream] =
        useState<MediaStream | null>(null);

    const [screenshareConfirmDialogOpen, setScreenshareConfirmDialogOpen] =
        useState(false);

    const whiteboardTabRef = useRef<Window | null>(null);

    const handleClickMicrophone = () => {
        setMicrophoneMuted((microphoneMuted) => {
            settings?.set('microphone_muted', !microphoneMuted);
            return !microphoneMuted;
        });
    };

    const handleClickCamera = () => {
        setCameraOff((cameraOff) => {
            settings?.set('camera_off', !cameraOff);
            return !cameraOff;
        });
    };

    const handleLeaveCall = () => {
        localMediaStream.getTracks().forEach((track) => {
            track.stop();
        });
        Object.values(rtcConnections).forEach((connection) => {
            connection.close();
        });
        wsClient?.close();

        // force hard reload page to ensure media stream stop
        window.location.href = '/endCall';
    };

    const handleClickChat = () => {
        setUnreadChatCount(0);
        setChatDrawerOpen((chatDrawerOpen) => !chatDrawerOpen);
    };

    // hook to determine screen size
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        // on start show our camera preview
        localMediaStream.getAudioTracks().forEach((track) => {
            track.enabled = !microphoneMuted;
        });
        localMediaStream.getVideoTracks().forEach((track) => {
            track.enabled = !cameraOff;
        });
        setConnectedDevices((connectedDevices) => ({
            ...connectedDevices,
            [selfUserId]: {
                [createPropperUUID(localMediaStream.id)]: localMediaStream,
            },
        }));

        const wsClient = new WsClient({
            onOpenHandler: () => {
                wsClient.login(
                    selfUserId as UUID,
                    roomData.uuid as UUID,
                    settings?.get('display_name') as string
                );
            },
            afterLoginHandler: (data) => {
                const newUserNames: { [key: string]: string } = {};
                data.all_users_in_room.forEach(async (peer) => {
                    // ignore self, so we dont fall into a connection loop
                    if (peer.uuid === selfUserId) {
                        return;
                    }

                    newUserNames[peer.uuid] = peer.display_name;

                    const RTCConnection = new RTCPeerConnection(rtcConfig);

                    RTCConnection.addEventListener('icecandidate', (e) => {
                        if (e.candidate) {
                            wsClient.sendIce(peer.uuid as UUID, e.candidate);
                        }
                    });

                    localMediaStream.getTracks().forEach((track) => {
                        RTCConnection.addTrack(track, localMediaStream);
                    });

                    screenshareMediastream
                        ?.getVideoTracks()
                        .forEach((track) => {
                            RTCConnection.addTransceiver(track, {
                                direction: 'sendonly',
                                streams: [screenshareMediastream],
                            });
                        });

                    RTCConnection.addEventListener('track', (e) => {
                        const [stream] = e.streams;
                        if (!stream) {
                            return;
                        }
                        setConnectedDevices((connectedDevices) => ({
                            ...connectedDevices,
                            [peer.uuid]: {
                                ...connectedDevices[peer.uuid],
                                [createPropperUUID(stream.id)]: stream,
                            },
                        }));
                    });

                    RTCConnection.addEventListener(
                        'negotiationneeded',
                        async () => {
                            const offer = await RTCConnection.createOffer({
                                offerToReceiveAudio: true,
                                offerToReceiveVideo: true,
                            });

                            await RTCConnection.setLocalDescription(offer);

                            wsClient.sendOffer(peer.uuid, offer);
                        }
                    );

                    setRtcConnections((rtcConnections) => ({
                        ...rtcConnections,
                        [peer.uuid]: RTCConnection,
                    }));
                });

                setUserDisplayNames((userDisplayNames) => ({
                    ...userDisplayNames,
                    ...newUserNames,
                }));

                // delay this sync by 1 second to help with other peers beeing loaded
                window.setTimeout(() => {
                    setMetaRequestQueue((_) => true);
                }, 1000);

                wsClient.sendChatSyncRequest();
            },
            onUserJoin: (data) => {
                if (data.event_target === selfUserId) {
                    return;
                }
                setUserDisplayNames((userDisplayNames) => ({
                    ...userDisplayNames,
                    [data.event_target]: data.target_display_name,
                }));

                setMetaRequestQueue((_) => true);
            },
            onUserLeft: (data) => {
                if (data.event_target === selfUserId) {
                    return;
                }

                rtcConnections[data.event_target]?.close();
                setRtcConnections((rtcConnections) => {
                    delete rtcConnections[data.event_target];
                    return { ...rtcConnections };
                });
                setConnectedDevices((connectedDevices) => {
                    delete connectedDevices[data.event_target];
                    return { ...connectedDevices };
                });
            },
            onOffer: async (data) => {
                if (data.sender === selfUserId) {
                    return;
                }

                setOfferQueue((offerQueue) => [...offerQueue, data]);
            },
            onAnswer: (data) => {
                setAnswerQueue((answearQueue) => [
                    ...answearQueue,
                    {
                        peer: data.sender,
                        answer: new RTCSessionDescription(data.answer),
                    },
                ]);
            },
            onIce: (data) => {
                setIceQueue((iceQueue) => [
                    ...iceQueue,
                    {
                        peer: data.sender,
                        candidate: new RTCIceCandidate(data.candidate),
                    },
                ]);
            },
            onMeta: (data) => {
                setUserMeta((userMeta) => ({
                    ...userMeta,
                    [data.sender]: {
                        microphone_muted: data.microphone_muted,
                        camera_off: data.camera_off,
                        screensharing: data.screensharing,
                    },
                }));
            },
            onChat: (data) => {
                setChatMessages((chatMessages) => ({
                    ...chatMessages,
                    [data.sent_at]: data,
                }));
                setUnreadChatCount((unreadChatCount) => unreadChatCount + 1);
            },
            onChatSync: (data) => {
                const incomingChatMessages: { [key: string]: ChatMessageType } =
                    {};
                data.chat.forEach((message) => {
                    incomingChatMessages[message.sent_at] = message;
                });
                setChatMessages((chatMessages) => ({
                    ...chatMessages,
                    ...incomingChatMessages,
                }));
            },
            onWhoHasChat: (data) => {
                setChatSyncRequestQueue((chatSyncRequestQueue) => [
                    ...chatSyncRequestQueue,
                    data.sender,
                ]);
            },
            onScreenshareStart: (data) => {
                setActiveScreenshares((activeScreenshares) => {
                    // prevent duplicates in array and don't trigger effect change
                    if (activeScreenshares.includes(data.screenshare_uuid)) {
                        return [...activeScreenshares];
                    }

                    return [
                        ...activeScreenshares,
                        data.screenshare_uuid as UUID,
                    ];
                });
            },
            onScreenshareStop: (data) => {
                setActiveScreenshares((activeScreenshares) =>
                    activeScreenshares.filter(
                        (value) => value !== data.screenshare_uuid
                    )
                );
                setConnectedDevices((connectedDevices) => {
                    delete connectedDevices[data.sender][data.screenshare_uuid];

                    return {
                        ...connectedDevices,
                    };
                });
            },
            onError: (error) => {
                console.warn(error);
            },
            onWsClose: () => {
                // if closed by server side attempt to reconnect
                if (!wsClient.shouldBeClosed) {
                    window.setTimeout(() => {
                        setReconnectToWs([]);
                    }, 5000);
                }
            },
        });

        setWsClient(wsClient);

        return () => {
            wsClient.close();
        };
    }, [reconnectToWs]);

    useEffect(() => {
        localMediaStream.getAudioTracks().forEach((track) => {
            track.enabled = !microphoneMuted;
        });
    }, [microphoneMuted]);

    useEffect(() => {
        localMediaStream.getVideoTracks().forEach((track) => {
            track.enabled = !cameraOff;
        });
    }, [cameraOff]);

    useEffect(() => {
        setMetaRequestQueue((_) => true);
    }, [microphoneMuted, cameraOff]);

    useEffect(() => {
        if (answearQueue.length > 0) {
            setAnswerQueue((answearQueue) => {
                answearQueue.forEach((elem) => {
                    if (rtcConnections[elem.peer]) {
                        rtcConnections[elem.peer].setRemoteDescription(
                            elem.answer
                        );
                    } else {
                        setTimeout(() => {
                            setAnswerQueue((answearQueue) => [
                                ...answearQueue,
                                elem,
                            ]);
                        }, 1000);
                    }
                });
                return [];
            });
        }
    }, [answearQueue]);

    useEffect(() => {
        if (offerQueue.length > 0) {
            if (!wsClient) {
                return;
            }
            setOfferQueue((offerQueue) => {
                offerQueue.forEach(async (data) => {
                    // if a connectio nalready exists this means we are renegotiating
                    // after a connection param change
                    if (rtcConnections[data.sender]) {
                        await rtcConnections[data.sender].setRemoteDescription(
                            new RTCSessionDescription(data.offer)
                        );

                        const answer = await rtcConnections[
                            data.sender
                        ].createAnswer();
                        await rtcConnections[data.sender].setLocalDescription(
                            answer
                        );

                        wsClient.sendAnswer(data.sender, answer);
                        return;
                    }

                    const RTCConnection = new RTCPeerConnection(rtcConfig);
                    RTCConnection.addEventListener('icecandidate', (e) => {
                        if (e.candidate) {
                            wsClient.sendIce(data.sender as UUID, e.candidate);
                        }
                    });

                    localMediaStream.getTracks().forEach((track) => {
                        RTCConnection.addTrack(track, localMediaStream);
                    });

                    screenshareMediastream
                        ?.getVideoTracks()
                        .forEach((track) => {
                            RTCConnection.addTransceiver(track, {
                                direction: 'sendonly',
                                streams: [screenshareMediastream],
                            });
                        });

                    RTCConnection.addEventListener('track', (e) => {
                        const [stream] = e.streams;
                        if (!stream) {
                            return;
                        }

                        setConnectedDevices((connectedDevices) => ({
                            ...connectedDevices,
                            [data.sender]: {
                                ...connectedDevices[data.sender],
                                [createPropperUUID(stream.id)]: stream,
                            },
                        }));
                    });

                    await RTCConnection.setRemoteDescription(
                        new RTCSessionDescription(data.offer)
                    );

                    const answer = await RTCConnection.createAnswer();
                    await RTCConnection.setLocalDescription(answer);

                    wsClient.sendAnswer(data.sender, answer);

                    RTCConnection.addEventListener(
                        'negotiationneeded',
                        async () => {
                            const offer = await RTCConnection.createOffer({
                                offerToReceiveAudio: true,
                                offerToReceiveVideo: true,
                            });

                            await RTCConnection.setLocalDescription(offer);

                            wsClient.sendOffer(data.sender, offer);
                        }
                    );

                    setRtcConnections((rtcConnections) => ({
                        ...rtcConnections,
                        [data.sender]: RTCConnection,
                    }));
                });
                return [];
            });
        }
    }, [offerQueue]);

    useEffect(() => {
        if (iceQueue.length > 0) {
            setIceQueue((iceQueue) => {
                iceQueue.forEach((elem) => {
                    if (rtcConnections[elem.peer]) {
                        rtcConnections[elem.peer].addIceCandidate(
                            elem.candidate
                        );
                    } else {
                        setTimeout(() => {
                            setIceQueue((iceQueue) => [...iceQueue, elem]);
                        }, 1000);
                    }
                });
                return [];
            });
        }
    }, [iceQueue]);

    useEffect(() => {
        if (chatSyncRequestQueue.length > 0) {
            setChatSyncRequestQueue((chatSyncRequestQueue) => {
                chatSyncRequestQueue.forEach((r) => {
                    // this is logic to split chat messages to roughly 100KB chunks (UTF-8 chars are improperly counted to improve speed).
                    // This is done to ensure no blockage in
                    // websocket client. Theoretical limit is 32M but chromium doesn't seem to handle more than 300 KB
                    const allChatMessages = Object.values(chatMessages);
                    var messagesChunk: Array<ChatMessageType> = [];

                    var chunkSize = 0;
                    allChatMessages.forEach((message, idx) => {
                        messagesChunk.push(message);
                        chunkSize += message.content.length;

                        // size > 100KB or on last element in array
                        if (
                            chunkSize > 100 * 1024 ||
                            idx === allChatMessages.length - 1
                        ) {
                            wsClient?.sendChatSyncMessage({
                                recipient: r,
                                chat: messagesChunk,
                            });
                            chunkSize = 0;
                            messagesChunk = [];
                        }
                    });
                });
                return [];
            });
        }
    }, [chatSyncRequestQueue]);

    const videoUnavailable = useMemo(() => {
        return !localMediaStream.getVideoTracks().length;
    }, [localMediaStream]);

    const audioUnavailable = useMemo(() => {
        return !localMediaStream.getAudioTracks().length;
    }, [localMediaStream]);

    useEffect(() => {
        if (metaRequestsQueue) {
            const request = {
                microphone_muted: microphoneMuted || audioUnavailable,
                camera_off: cameraOff || videoUnavailable,
                screensharing: !!screenshareMediastream,
            };
            // sometimes the first meta request goes out before wsConn is established
            // this is not a problem since it's just meta
            try {
                wsClient?.sendMeta(request);

                // when a new user joins sync screen shares
                if (screenshareMediastream) {
                    wsClient?.sendStartScreenshareRequest({
                        screenshare_uuid: createPropperUUID(
                            screenshareMediastream.id
                        ),
                    });
                }
            } catch {}

            setMetaRequestQueue((_) => false);
        }
    }, [metaRequestsQueue]);

    useEffect(() => {
        if (screenshareMediastream) {
            const videoTrack = screenshareMediastream.getVideoTracks()[0];

            videoTrack.addEventListener('ended', () => {
                handleEndScreenShare();
            });

            Object.values(rtcConnections).forEach((conn) => {
                conn.addTransceiver(videoTrack, {
                    direction: 'sendonly',
                    streams: [screenshareMediastream],
                });
            });
        }
        setMetaRequestQueue((_) => true);
    }, [screenshareMediastream]);

    const handleScreenShareClick = () => {
        if (screenshareMediastream) {
            handleEndScreenShare();
        } else {
            if (settings?.get('screenshare_notice_dont_show_again')) {
                handleScreenShare();
            } else {
                setScreenshareConfirmDialogOpen(true);
            }
        }
    };

    const handleEndScreenShare = () => {
        if (screenshareMediastream) {
            screenshareMediastream.getTracks().forEach((track) => {
                track.stop();
            });

            wsClient?.sendStopScreenshareRequest({
                screenshare_uuid: createPropperUUID(screenshareMediastream.id),
            });

            setScreenshareMediastream(null);
        }
    };

    const handleScreenShare = async () => {
        const dispMedia = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: false,
        });

        if (dispMedia.getVideoTracks().length > 0) {
            setScreenshareMediastream(dispMedia);
        }

        wsClient?.sendStartScreenshareRequest({
            screenshare_uuid: createPropperUUID(dispMedia.id) as UUID,
        });
    };

    const handleWhiteboardClick = () => {
        // avoid duplicating the whiteboard tab
        // if exists try to focus open tab
        if (!whiteboardTabRef.current || whiteboardTabRef.current.closed) {
            const tab = window.open(
                `/whiteboard/${roomData.uuid}`,
                'whiteboard',
                'popup=true'
            );
            whiteboardTabRef.current = tab;
        } else {
            whiteboardTabRef.current.focus();
        }
    };

    return (
        <DefaultLayout>
            <Box
                sx={{
                    flex: 1,
                    backgroundColor: theme.palette.secondary.main,
                    height: '100vh',
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Paper
                    elevation={2}
                    sx={{
                        padding: 1,
                        borderRadius: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <img
                        src="/igabinet_cross_icon.svg"
                        alt="igabinet cross icon"
                        style={{
                            height: '1.5rem',
                            pointerEvents: 'none',
                        }}
                    />
                    <Typography
                        sx={{
                            userSelect: 'none',
                        }}
                    >
                        {languagePack.meetingWith}: {roomData?.doctors_name}
                    </Typography>
                </Paper>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'center',
                        gap: 2,
                        padding: isMobile ? 0 : 2,
                        minHeight: 0,
                        minWidth: 0,
                    }}
                >
                    {Object.entries(connectedDevices).map(([peer, streams]) =>
                        // Mute self
                        Object.entries(streams).map(([streamUUID, stream]) => (
                            <VideoStream
                                mute={peer === selfUserId}
                                key={streamUUID}
                                srcObject={stream}
                                displayName={userDisplayNames[peer]}
                                userMeta={userMeta[peer as UUID]}
                                isScreenShare={activeScreenshares.includes(
                                    streamUUID as UUID
                                )}
                            />
                        ))
                    )}
                </Box>
                <Paper
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        borderRadius: 0,
                    }}
                >
                    <Tooltip title={languagePack.mute}>
                        <IconButton
                            size="large"
                            color={microphoneMuted ? 'error' : 'primary'}
                            onClick={handleClickMicrophone}
                            disabled={audioUnavailable}
                        >
                            {!microphoneMuted && <MicIcon />}
                            {microphoneMuted && <MicOffIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={languagePack.hideCamera}>
                        <span>
                            <IconButton
                                size="large"
                                onClick={handleClickCamera}
                                color={cameraOff ? 'error' : 'primary'}
                                disabled={videoUnavailable}
                            >
                                {!cameraOff && <CameraOnIcon />}
                                {cameraOff && <CameraOffIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={
                            !!screenshareMediastream
                                ? languagePack.stopScreenSharing
                                : languagePack.startScreenShare
                        }
                    >
                        <IconButton
                            size="large"
                            color={
                                !!screenshareMediastream ? 'error' : 'primary'
                            }
                            onClick={handleScreenShareClick}
                        >
                            <CastOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={languagePack.chat}>
                        <IconButton color="primary" onClick={handleClickChat}>
                            <Badge badgeContent={unreadChatCount} color="error">
                                <ChatIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {isDesktop && (
                        <Tooltip
                            title={
                                !!roomData.visit_start
                                    ? languagePack.whiteboard
                                    : languagePack.noWhiteboardInReservation
                            }
                        >
                            <span>
                                <IconButton
                                    color="primary"
                                    size="large"
                                    disabled={!roomData.visit_start}
                                    onClick={handleWhiteboardClick}
                                >
                                    <CreateOutlinedIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip title={languagePack.leave}>
                        <IconButton color="error" onClick={handleLeaveCall}>
                            <CallEndIcon />
                        </IconButton>
                    </Tooltip>
                </Paper>
            </Box>
            <ChatDrawer
                open={chatDrawerOpen}
                onClose={() => {
                    setChatDrawerOpen(false);
                    setUnreadChatCount(0);
                }}
                wsClient={wsClient}
                chatMessages={chatMessages}
                userDisplayNames={userDisplayNames}
            />
            <ConfirmScreenshare
                dialogOpen={screenshareConfirmDialogOpen}
                onCancel={() => {
                    setScreenshareConfirmDialogOpen(false);
                }}
                onConfirm={() => {
                    setScreenshareConfirmDialogOpen(false);
                    handleScreenShare();
                }}
            />
        </DefaultLayout>
    );
};
export default CallRoom;

const createPropperUUID = (input: string): UUID => {
    return input.replaceAll(/\{|\}/g, '') as UUID;
};
