import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { LanguageSelector } from '../languagepack/Languagepack';
import CookiesPopup from '../components/CookiesPopup';

const DefaultLayout = ({ children }: { children?: ReactNode }) => {
    return (
        <Box
            sx={{
                minWidth: '100vw',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <LanguageSelector />
            {children}
            <CookiesPopup />
        </Box>
    );
};
export default DefaultLayout;
