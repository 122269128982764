import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CallPage from './pages/call/[:uuid]';
import Error404Page from './pages/404';
import EndCallPage from './pages/endCall';
import WhiteboardPage from './pages/whiteboard/[:uuid]';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/call/:uuid" element={<CallPage />} />
                <Route path="/whiteboard/:uuid" element={<WhiteboardPage />} />
                <Route path="/endCall" element={<EndCallPage />} />
                <Route path="*" element={<Error404Page />} />
            </Routes>
        </BrowserRouter>
    );
};
export default Router;
