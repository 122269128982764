/* eslint-disable react-hooks/exhaustive-deps*/
import {
    Box,
    Dialog,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import useLanguagepack from '../languagepack/Languagepack';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, useEffect, useState } from 'react';
import useSelectedDevices from '../contexts/SelectedDevicesContext';
import CameraswitchOutlinedIcon from '@mui/icons-material/CameraswitchOutlined';
import useSettingsContext from '../contexts/SettingsContext';

const SettingsModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: VoidFunction;
}) => {
    const languagepack = useLanguagepack();
    const selectedDevices = useSelectedDevices();
    const settings = useSettingsContext();
    const theme = useTheme();
    const [availableDevices, setAvailableDevices] = useState<
        Array<MediaDeviceInfo>
    >([]);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            setAvailableDevices(devices);

            if (
                !selectedDevices.cameraId ||
                !devices.filter((d) => d.deviceId === selectedDevices.cameraId)
                    .length
            ) {
                selectedDevices.setCameraId(
                    devices.filter((d) => d.kind === 'videoinput')[0]?.deviceId
                );
            }

            if (
                !selectedDevices.microphoneId ||
                !devices.filter(
                    (d) => d.deviceId === selectedDevices.microphoneId
                ).length
            ) {
                selectedDevices.setMicrophoneId(
                    devices.filter((d) => d.kind === 'audioinput')[0]?.deviceId
                );
            }
        });
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <Box
                sx={{
                    padding: 2,
                    minWidth: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Dont show media device choice when there is no choice */}
                {availableDevices.length > 0 && (
                    <Fragment>
                        <FormControl
                            sx={{
                                flex: 1,
                            }}
                        >
                            <InputLabel id="select-camera-label">
                                {languagepack.camera}
                            </InputLabel>
                            <Select
                                label={languagepack.camera}
                                labelId="select-camera-label"
                                value={selectedDevices.cameraId || ''}
                                onChange={(e) => {
                                    selectedDevices.setCameraId(e.target.value);
                                }}
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value="" disabled={true}>
                                    {languagepack.none}
                                </MenuItem>
                                {availableDevices
                                    .filter((d) => d.kind === 'videoinput')
                                    .map((d) => (
                                        <MenuItem
                                            key={d.deviceId}
                                            value={d.deviceId}
                                        >
                                            {d.label ||
                                                languagepack.unknownDevice}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                        <FormControl>
                            <InputLabel id="select-microphone-label">
                                {languagepack.microphone}
                            </InputLabel>
                            <Select
                                label={languagepack.microphone}
                                labelId="select-microphone-label"
                                value={selectedDevices.microphoneId || ''}
                                onChange={(e) => {
                                    selectedDevices.setMicrophoneId(
                                        e.target.value
                                    );
                                }}
                                variant="outlined"
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <MenuItem value="" disabled={true}>
                                    {languagepack.none}
                                </MenuItem>
                                {availableDevices
                                    .filter((d) => d.kind === 'audioinput')
                                    .map((d) => (
                                        <MenuItem
                                            key={d.deviceId}
                                            value={d.deviceId}
                                        >
                                            {d.label ||
                                                languagepack.unknownDevice}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Fragment>
                )}
                {!!isMobile && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => {
                                if (selectedDevices.facingMode === 'user') {
                                    selectedDevices.setFacingMode(
                                        'environment'
                                    );
                                } else {
                                    selectedDevices.setFacingMode('user');
                                }
                            }}
                        >
                            <CameraswitchOutlinedIcon />
                        </IconButton>
                    </Box>
                )}
                <FormControl>
                    <InputLabel id="select-chat-anchor">
                        {languagepack.chatPosition}
                    </InputLabel>
                    <Select
                        label={languagepack.chatPosition}
                        labelId="select-chat-anchor"
                        value={settings?.get('chat_anchor') || 'right'}
                        onChange={(e) => {
                            settings?.set(
                                'chat_anchor',
                                e.target.value as string
                            );
                        }}
                        variant="outlined"
                        sx={{
                            flex: 1,
                        }}
                    >
                        <MenuItem value="right">{languagepack.right}</MenuItem>
                        <MenuItem value="left">{languagepack.left}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Dialog>
    );
};
export default SettingsModal;
