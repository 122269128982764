import { ReactNode, createContext, useContext, useState } from 'react';
import { callRoomDataType } from '../apiClient/ApiClient.types';

type roomDataContextType = {
    setRoomData: (roomData: callRoomDataType) => void;
    roomData: callRoomDataType | null;
};

const RoomDataContext = createContext<roomDataContextType>({
    setRoomData: (roomData: callRoomDataType) => {},
    roomData: null,
});

export const RoomDataContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [roomData, setRoomData] = useState<callRoomDataType | null>(null);

    return (
        <RoomDataContext.Provider
            value={{
                roomData: roomData,
                setRoomData: (roomData) => {
                    setRoomData(roomData);
                },
            }}
        >
            {children}
        </RoomDataContext.Provider>
    );
};

const useRoomData = () => {
    const roomDataContext = useContext(RoomDataContext);

    return roomDataContext;
};
export default useRoomData;
