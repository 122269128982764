const ua = {
    displayName: "Ваше ім'я",
    join: 'Приєднатися',
    you: 'Ви',
    meetingWith: 'Зустріч з',
    message: 'Повідомлення',
    mute: 'Вимкнути/Увімкнути звук',
    hideCamera: 'Ввімкнути/Вимкнути камеру',
    chat: 'Чат',
    leave: 'Вийти',
    fullscreen: 'На весь екран',
    attachment: 'Вкладення',
    send: 'Відправити',
    noMediaDevice: 'Немає доступних мультимедійних пристроїв',
    endCall:
        'Дякуємо, що скористалися електронним візитом iGabinet. Тепер ви можете безпечно закрити це вікно браузера',
    backToCall: 'Повернутися до виклику',
    or: 'Або',
    uploadSizeLimit: 'Розмір вкладення не повинен перевищувати 10 МБ',
    uploadFailed: 'Помилка завантаження файлу',
    noFileUploadsForReservations: 'Вкладення недоступні для бронювань',
    displayNameRequired: "Потрібно вказати ваше ім'я",
    roomNotFound: 'Електронний візит не знайдено або він був закритий',
    camera: 'Камера',
    microphone: 'Мікрофон',
    none: 'Немає',
    settings: 'Налаштування',
    unknownDevice: 'Невідомий пристрій',
    chatPosition: 'Позиція чату',
    right: 'Праворуч',
    left: 'Ліворуч',
    screenShare: 'екран',
    startScreenShare: 'поділитися екраном',
    stopScreenSharing: 'Завершіть показ екрана',
    ok: 'ПРИБЛ',
    cancel: 'Скасувати',
    youreAboutToScreenshare: 'Примітка. Ви збираєтеся поділитися своїм екраном',
    screenshareNotice:
        'переконайтеся, що ви не передаєте медичні/конфіденційні дані',
    dontShowAgain: 'більше не показувати',
    whiteboard: 'Інтерактивна дошка',
    noWhiteboardInReservation: 'Дошка недоступна для бронювання',
    cookiesPopupContent:
        'Використовуючи цей сайт, ви даєте згоду на використання файлів cookie та локального зберігання даних.',
};

export default ua;
