/* eslint-disable react-hooks/exhaustive-deps*/

import { Fragment, useEffect } from 'react';
import Whiteboard from '../../components/Whiteboard';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../../apiClient/ApiClient';

const WhiteboardPage = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (uuid) {
            apiClient
                .getRoomData(uuid)
                .then((roomData) => {
                    if (!roomData) {
                        navigate('/404');
                    }
                })
                .catch((e) => {
                    console.warn(e);
                });
        }
    }, [uuid]);

    if (!uuid) {
        return <Fragment></Fragment>;
    }

    return <Whiteboard whiteboardId={uuid} />;
};
export default WhiteboardPage;
