import { ReactNode, createContext, useContext, useState } from 'react';

type localMediaStreamContextType = {
    setLocalMediaStream: (mediaStream: MediaStream) => void;
    localMediaStream: MediaStream | null;
};
const LocalMediaStreamContext = createContext<localMediaStreamContextType>({
    setLocalMediaStream: (mediaStream) => {},
    localMediaStream: null,
});

export const LocalMediaStreamContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [localMediaStream, setLocalMediaStream] =
        useState<MediaStream | null>(null);

    return (
        <LocalMediaStreamContext.Provider
            value={{
                setLocalMediaStream: (mediaStream) => {
                    setLocalMediaStream(mediaStream);
                },
                localMediaStream: localMediaStream,
            }}
        >
            {children}
        </LocalMediaStreamContext.Provider>
    );
};

const useLocalMediaStreamContext = () => {
    const localMediaStreamContext = useContext(LocalMediaStreamContext);
    return localMediaStreamContext;
};
export default useLocalMediaStreamContext;
