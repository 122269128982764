/* eslint-disable react-hooks/exhaustive-deps*/

/*
This whole module could be refactored to simplify ~200~ lines of code and improve
readability. Tell that to an uderstaffed office with no paid IDE's. VS CODE for the win
*/

import {
    Box,
    IconButton,
    Menu,
    Paper,
    Slider,
    Stack,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import PanToolIcon from '@mui/icons-material/PanTool';
import ModeIcon from '@mui/icons-material/Mode';
import debounce from 'lodash/debounce';
import WhiteboardClient from '../whiteboardClient/WhiteboardClient';
import useSelfUserUuid from '../contexts/SelfUserUuidContext';
import {
    ExtendedSyncRequest,
    SyncRequest,
} from '../whiteboardClient/WhiteboardClient.types';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import useWhiteboardSettingsContext from '../contexts/WhiteboardSettingsContext';
import apiClient, { baseApiURL } from '../apiClient/ApiClient';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import { UploadedImage, UploadedImagesType, tool } from './Whiteboard.types';

// this consts should theoretically make it easy to extend available tools
const CANVAS_MAX_WIDTH = 3000;
const CANVAS_MAX_HEIGHT = 3000;
const MOVE_MULTIPLEIER = 1;
const UPLOADED_MEDIA_MAX_WIDTH = 2000;
const AVAILABLE_COLORS = [
    '#000000',
    '#4254f5',
    '#ff19f7',
    '#19ff25',
    '#ff0019',
    '#00fffb',
];
const TOOL_TO_POINTER_MAP = {
    move: 'move',
    pencil: 'crosshair',
    eraser: 'cell',
    moveMedia: 'pointer',
};
const SYNCHRONISED_TOOLS = ['pencil', 'eraser'];

const zIndexes = {
    whiteboardLayer: 10000,
    menuLayer: 30000,
    menuOverlay: 50000,
    drawingLayer: 25000,
    aboveDrawingLayer: 26000,
    belowDrawingLayer: 20000,
};

// debounced function to send sync data over websocket
const syncTools = debounce(
    (syncQueue, setSyncQueue, whiteboardClient: WhiteboardClient) => {
        // send the whole queue and reset it
        whiteboardClient.sendSync(syncQueue);

        setSyncQueue([]);
    },
    300,
    {
        maxWait: 1000,
    }
);

// debounced function to snapshot and save whiteboard state
const saveWhiteboardState = debounce(
    async (
        canvas: HTMLCanvasElement | null,
        uploadedImages: UploadedImagesType,
        whiteboardId: string,
        whiteboardClient: WhiteboardClient | null
    ) => {
        // basicly make a png image of the canvas, upload it as a file
        // then tell the backend to upsert uploaded image uuid
        // and save state of aploaded attachments
        // canvas and client are nullable because technically then can be null on first hydration but it will never happen
        canvas?.toBlob(async (blob) => {
            if (!blob) {
                return;
            }

            const response = await apiClient.uploadFile(
                whiteboardId,
                new File([blob], 'whiteboardSync.png')
            );

            if (!response) {
                return;
            }

            whiteboardClient?.saveWhiteboardState({
                image_uuid: response.uuid,
                uploaded_images: JSON.stringify(uploadedImages),
            });
        });
    },
    5000,
    { maxWait: 30000 }
);

const Whiteboard = ({ whiteboardId }: { whiteboardId: string }) => {
    const whiteboardSettings = useWhiteboardSettingsContext();
    const selfUserId = useSelfUserUuid();

    // these refs are needed to get and manipulate canvas in callback functions
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasContainer = useRef<HTMLDivElement>(null);

    const [selectedTool, _setSelectedTool] = useState<tool>('move');
    // local initail tools state
    const [initialCords, _setInitialCords] = useState({
        x: 0,
        y: 0,
    });

    // anchors to display menus after double click
    const [pencilMenuAnchor, setPencilMenuAnchor] =
        useState<null | HTMLElement>(null);

    const [eraserMenuAnchor, setEraserMenuAnchor] =
        useState<null | HTMLElement>(null);

    // WS client
    const [whiteboardClient, setWhiteboardClient] =
        useState<WhiteboardClient | null>(null);

    // syncQueue is responsible for holding and synchronising steps to reproduce on every client
    const [syncQueue, setSyncQueue] = useState<Array<SyncRequest>>([]);

    // Queue to send ws responses
    const [syncResponseQueue, setSyncResponseQueue] = useState<
        Array<ExtendedSyncRequest>
    >([]);

    // try to get settings from localstorage or use default value
    const [pencilSize, _setPencilSize] = useState<number>(
        (whiteboardSettings?.get('pencil_size') as number) ?? 10
    );
    const [pencilColor, _setPencilColor] = useState(
        (whiteboardSettings?.get('pencil_color') as string) ??
            AVAILABLE_COLORS[0]
    );
    const [eraserSize, _setEraserSize] = useState(
        (whiteboardSettings?.get('eraser_size') as number) ?? 20
    );

    // uploaded images image UUID -> image properties
    const [uploadedImages, _setUploadedImages] = useState<UploadedImagesType>(
        {}
    );

    // every remote user needs a separate drawing context not to interfere with local drawing or other users
    const remoteDrawContexts = useRef<{
        [key: string]: CanvasRenderingContext2D;
    }>({});
    const breakSyncs = useRef<{ [key: string]: boolean }>({});
    const remoteLastKnownPosRef = useRef<{
        [key: string]: {
            x: number;
            y: number;
        };
    }>({});

    // we need refs to access the current state in deep callbacks
    const eraserSizeRef = useRef(eraserSize);
    const initialCordsRef = useRef(initialCords);
    const selectedToolRef = useRef(selectedTool);
    const drawCtxRef = useRef<CanvasRenderingContext2D | null>(null);
    const pencilSizeRef = useRef(pencilSize);
    const pencilColorRef = useRef(pencilColor);
    const uploadImageInputRef = useRef<HTMLInputElement>(null);
    const uploadedImagesRef = useRef(uploadedImages);

    // wrappers around setters to also update refs
    const setEraserSize = (size: number) => {
        _setEraserSize(size);
        eraserSizeRef.current = size;
        whiteboardSettings?.set('eraser_size', size);
    };

    const setPencilSize = (value: number) => {
        _setPencilSize(value);
        pencilSizeRef.current = value;
        whiteboardSettings?.set('pencil_size', value);
    };

    const setPencilColor = (color: string) => {
        _setPencilColor(color);
        pencilColorRef.current = color;
        whiteboardSettings?.set('pencil_color', color);
    };

    const setInitialCords = (initialCords: { x: number; y: number }) => {
        initialCordsRef.current = initialCords;
        _setInitialCords(initialCords);
    };

    const setSelectedTool = (tool: tool) => {
        selectedToolRef.current = tool;
        _setSelectedTool(tool);

        updatePointer();
    };

    const addUploadedImage = (image: UploadedImage) => {
        _setUploadedImages((uploadedImages) => {
            const newUplaodedImages = {
                ...uploadedImages,
                [image.uuid]: image,
            };
            uploadedImagesRef.current = newUplaodedImages;
            return newUplaodedImages;
        });
    };

    const removeUploadedImage = (uuid: string) => {
        _setUploadedImages((uploadedImages) => {
            delete uploadedImages[uuid];

            const newUplaodedImages = { ...uploadedImages };

            uploadedImagesRef.current = newUplaodedImages;
            return newUplaodedImages;
        });
    };

    useEffect(() => {
        const whiteboardClient = new WhiteboardClient({
            onOpenHandler: () => {
                whiteboardClient.login(selfUserId, whiteboardId);
            },
            onSyncHandler: (data) => {
                // ignore self. WS backend is simple for best performance
                // and acts like a broadcaster to a room
                if (data.sender === selfUserId) {
                    return;
                }

                // add senders uuid to sync elements
                // this helps to keep track of who is drawing what
                // and so that line doesnt jump between points of
                // diffrent users
                const extendedData = data.steps.map((elem) => ({
                    ...elem,
                    sender_uuid: data.sender,
                }));

                setSyncResponseQueue((syncResponseQueue) => [
                    ...syncResponseQueue,
                    ...extendedData,
                ]);
            },
            onLoginHandler: async (data) => {
                // if a snapshot of previous state exists load it
                if (data.whiteboard_initial_state) {
                    // get image, load it and draw on canvas
                    const initialStateImage = await apiClient.getFileContents(
                        whiteboardId,
                        data.whiteboard_initial_state.image_uuid
                    );
                    if (!initialStateImage) {
                        return;
                    }

                    const image = new Image();

                    image.onload = () => {
                        drawCtxRef.current?.drawImage(image, 0, 0);
                    };

                    image.src = URL.createObjectURL(initialStateImage);
                    // uploaded images
                    const initialUploadedImages: UploadedImagesType =
                        JSON.parse(
                            data.whiteboard_initial_state.uploaded_images
                        );

                    _setUploadedImages(initialUploadedImages);
                    uploadedImagesRef.current = initialUploadedImages;
                }

                whiteboardClient.requestInitialSync();
            },
            onWhiteboardInitalSyncRequest: (data) => {
                // when someone asks for sync make an image of canvas and upload it
                if (data.sender === selfUserId || !canvasRef.current) {
                    return;
                }

                canvasRef.current.toBlob(async (blob) => {
                    if (!blob) {
                        return;
                    }

                    const response = await apiClient.uploadFile(
                        whiteboardId,
                        new File([blob], 'whiteboardSync.png')
                    );

                    if (!response) {
                        return;
                    }

                    // send snaphost of current board
                    whiteboardClient.sendInitialSyncPayload({
                        target: data.sender,
                        image_uuid: response.uuid,
                        uploaded_images: JSON.stringify(
                            uploadedImagesRef.current
                        ),
                    });
                });
            },
            onWhiteboardInitialSyncResponse: async (data) => {
                if (data.sender === selfUserId) {
                    return;
                }

                // when we recive a whiteboard snapshot from another user

                const response = await apiClient.getFileContents(
                    whiteboardId,
                    data.image_uuid
                );

                if (!response) {
                    return;
                }

                const image = new Image();

                image.onload = () => {
                    drawCtxRef.current?.drawImage(image, 0, 0);
                };

                image.src = URL.createObjectURL(response);

                const uploadedImages = JSON.parse(data.uploaded_images);
                _setUploadedImages(uploadedImages);
                uploadedImagesRef.current = uploadedImages;
            },
        });

        setWhiteboardClient(whiteboardClient);

        const { innerWidth: width, innerHeight: height } = window;

        // as long as HTML was created correctly these can not be null
        if (canvasRef.current && canvasContainer.current) {
            const ctxLocal = canvasRef.current.getContext('2d');

            // set a ref to a drawing context
            drawCtxRef.current = ctxLocal;

            // scroll to the middle of the whiteboard
            canvasContainer.current.scroll(
                CANVAS_MAX_WIDTH / 2 - width / 2,
                CANVAS_MAX_HEIGHT / 2 - height / 2
            );
        }
    }, []);

    useEffect(() => {
        // upload file button handler
        uploadImageInputRef.current?.addEventListener('change', async (e) => {
            const file = (e.target as HTMLInputElement).files?.item(0);

            if (!file) {
                return;
            }

            const response = await apiClient.uploadFile(whiteboardId, file);

            if (!response) {
                return;
            }

            const imageObject = new Image();

            // we need to load the image to use its width/height
            imageObject.onload = () => {
                addUploadedImage({
                    uuid: response.uuid,
                    x: initialCordsRef.current.x,
                    y: initialCordsRef.current.y,
                    width: imageObject.width,
                    height: imageObject.height,
                });

                setSyncQueue((syncQueue) => {
                    return [
                        ...syncQueue,
                        {
                            tool: 'addImage',
                            x: 0, // this x and y is irrevevant as true x,y are sent in settings
                            y: 0, // whole img object is serialized in settings
                            settings: {
                                uuid: response.uuid,
                                x: initialCordsRef.current.x,
                                y: initialCordsRef.current.y,
                                width: imageObject.width,
                                height: imageObject.height,
                            },
                        },
                    ];
                });
            };

            imageObject.src = URL.createObjectURL(file);
        });

        if (!canvasRef.current) {
            return;
        }
        // acual move logic

        // update css of cursor based on selected tool
        updatePointer();

        // bind handlers for mouse events
        canvasRef.current.addEventListener('mousedown', handleMouseDown);
        canvasRef.current.addEventListener('mousemove', handleMouseMove);
        canvasRef.current.addEventListener('mouseup', handleMouseUp);
    }, []);

    useEffect(() => {
        // on change to a whiteboard call debounced sync functions
        if (!whiteboardClient) {
            return;
        }
        if (syncQueue.length > 0) {
            syncTools(syncQueue, setSyncQueue, whiteboardClient);
            saveWhiteboardState(
                canvasRef.current,
                uploadedImages,
                whiteboardId,
                whiteboardClient
            );
        }
    }, [syncQueue]);

    useEffect(() => {
        // queue of steps to reproduce when responses from other users come
        if (!syncResponseQueue.length) {
            return;
        }
        // make updates in a callback func not to mess with state
        // this way we get synchronised ie. no part of quqe ever gets skipped
        setSyncResponseQueue((syncResponseQueue) => {
            // handle each sync command
            syncResponseQueue.forEach((elem) => {
                if (!canvasRef.current) {
                    return;
                }

                let drawCtx: CanvasRenderingContext2D | null =
                    remoteDrawContexts.current[elem.sender_uuid];

                // if there is no drawCTX it means that this is a first sync from a new user
                if (!drawCtx) {
                    drawCtx = canvasRef.current.getContext('2d');

                    if (!drawCtx) {
                        return;
                    }

                    // initialize syncs for a new user
                    remoteDrawContexts.current[elem.sender_uuid] = drawCtx;
                    breakSyncs.current[elem.sender_uuid] = true;
                    remoteLastKnownPosRef.current[elem.sender_uuid] = {
                        x: 0,
                        y: 0,
                    };
                }

                switch (elem.tool) {
                    case 'pencil': {
                        // almost the same logic as in local draw function
                        if (breakSyncs.current[elem.sender_uuid]) {
                            remoteLastKnownPosRef.current[elem.sender_uuid] = {
                                x: elem.x,
                                y: elem.y,
                            };
                            breakSyncs.current[elem.sender_uuid] = false;
                        }

                        drawCtx.lineWidth = elem.settings.pencil_size;
                        drawCtx.lineCap = 'round';
                        drawCtx.strokeStyle = elem.settings.pencil_color;

                        drawCtx.beginPath();

                        drawCtx.moveTo(
                            remoteLastKnownPosRef.current[elem.sender_uuid].x,
                            remoteLastKnownPosRef.current[elem.sender_uuid].y
                        );

                        remoteLastKnownPosRef.current[elem.sender_uuid] = {
                            x: elem.x,
                            y: elem.y,
                        };

                        drawCtx.lineTo(elem.x, elem.y);

                        drawCtx.stroke();
                        break;
                    }
                    case 'eraser': {
                        if (breakSyncs.current[elem.sender_uuid]) {
                            remoteLastKnownPosRef.current[elem.sender_uuid] = {
                                x: elem.x,
                                y: elem.y,
                            };
                            breakSyncs.current[elem.sender_uuid] = false;
                        }

                        drawCtx.beginPath();

                        drawCtx.lineWidth = elem.settings.eraser_size;
                        drawCtx.lineCap = 'round';
                        drawCtx.globalCompositeOperation = 'destination-out';
                        drawCtx.moveTo(
                            remoteLastKnownPosRef.current[elem.sender_uuid].x,
                            remoteLastKnownPosRef.current[elem.sender_uuid].y
                        );

                        remoteLastKnownPosRef.current[elem.sender_uuid] = {
                            x: elem.x,
                            y: elem.y,
                        };

                        drawCtx.lineTo(elem.x, elem.y);

                        drawCtx.stroke();

                        drawCtx.globalCompositeOperation = 'source-over';
                        break;
                    }
                    case 'break': {
                        breakSyncs.current[elem.sender_uuid] = true;
                        break;
                    }
                    case 'addImage': {
                        addUploadedImage(elem.settings);
                        break;
                    }
                    case 'removeImage': {
                        removeUploadedImage(elem.settings.uuid);
                    }
                }
            });

            // reset queue
            return [];
        });
    }, [syncResponseQueue]);

    // function to update css cursor
    const updatePointer = () => {
        if (!canvasRef.current) {
            return;
        }

        canvasRef.current.style.cursor =
            TOOL_TO_POINTER_MAP[selectedToolRef.current];
    };

    const handleMouseUp = (e: MouseEvent) => {
        // on mouse up we send break sync to stop a line on all remote users
        if (SYNCHRONISED_TOOLS.includes(selectedToolRef.current)) {
            setSyncQueue((syncQueue) => {
                if (!canvasContainer.current) {
                    return syncQueue;
                }

                return [
                    ...syncQueue,
                    {
                        tool: 'break',
                        x: e.clientX + canvasContainer.current.scrollLeft,
                        y: e.clientY + canvasContainer.current.scrollTop,
                        settings: {},
                    },
                ];
            });
        }
    };

    const handleMouseDown = (e: MouseEvent) => {
        if (!canvasContainer.current) {
            return;
        }

        // Starts a line and sets coord states

        setInitialCords({
            x: e.clientX + canvasContainer.current.scrollLeft,
            y: e.clientY + canvasContainer.current.scrollTop,
        });

        switch (selectedToolRef.current) {
            case 'pencil': {
                draw({
                    x: e.clientX + canvasContainer.current.scrollLeft,
                    y: e.clientY + canvasContainer.current.scrollTop,
                });

                break;
            }
            case 'eraser': {
                erase({
                    x: e.clientX + canvasContainer.current.scrollLeft,
                    y: e.clientY + canvasContainer.current.scrollTop,
                });
                break;
            }
        }

        if (SYNCHRONISED_TOOLS.includes(selectedToolRef.current)) {
            setSyncQueue((syncQueue) => {
                if (!canvasContainer.current) {
                    return syncQueue;
                }

                return [
                    ...syncQueue,
                    {
                        tool: selectedToolRef.current,
                        x: e.clientX + canvasContainer.current.scrollLeft,
                        y: e.clientY + canvasContainer.current.scrollTop,
                        settings: {
                            pencil_size: pencilSizeRef.current,
                            pencil_color: pencilColorRef.current,
                            eraser_size: eraserSizeRef.current,
                        },
                    },
                ];
            });
        }
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (
            e.buttons !== 1 ||
            !drawCtxRef.current ||
            !canvasContainer.current
        ) {
            return;
        }

        switch (selectedToolRef.current) {
            case 'move': {
                canvasContainer.current?.scrollBy(
                    (initialCordsRef.current.x -
                        (e.x + canvasContainer.current.scrollLeft)) *
                        MOVE_MULTIPLEIER,
                    (initialCordsRef.current.y -
                        (e.y + canvasContainer.current.scrollTop)) *
                        MOVE_MULTIPLEIER
                );
                setInitialCords({
                    x: e.x + canvasContainer.current.scrollLeft,
                    y: e.y + +canvasContainer.current.scrollTop,
                });
                break;
            }
            case 'pencil': {
                draw({
                    x: e.clientX + canvasContainer.current.scrollLeft,
                    y: e.clientY + canvasContainer.current.scrollTop,
                });

                setSyncQueue((syncQueue) => {
                    if (!canvasContainer.current) {
                        return syncQueue;
                    }

                    return [
                        ...syncQueue,
                        {
                            tool: selectedToolRef.current,
                            x: e.clientX + canvasContainer.current.scrollLeft,
                            y: e.clientY + canvasContainer.current.scrollTop,
                            settings: {
                                pencil_size: pencilSizeRef.current,
                                pencil_color: pencilColorRef.current,
                            },
                        },
                    ];
                });

                break;
            }
            case 'eraser': {
                erase({
                    x: e.clientX + canvasContainer.current.scrollLeft,
                    y: e.clientY + canvasContainer.current.scrollTop,
                });

                setSyncQueue((syncQueue) => {
                    if (!canvasContainer.current) {
                        return syncQueue;
                    }

                    return [
                        ...syncQueue,
                        {
                            tool: selectedToolRef.current,
                            x: e.clientX + canvasContainer.current.scrollLeft,
                            y: e.clientY + canvasContainer.current.scrollTop,
                            settings: {
                                eraser_size: eraserSizeRef.current,
                            },
                        },
                    ];
                });

                break;
            }
        }
    };

    const draw = ({ x, y }: { x: number; y: number }) => {
        if (!drawCtxRef.current) {
            return;
        }

        drawCtxRef.current.beginPath();

        drawCtxRef.current.lineWidth = pencilSizeRef.current;
        drawCtxRef.current.lineCap = 'round';
        drawCtxRef.current.strokeStyle = pencilColorRef.current;

        drawCtxRef.current.moveTo(
            initialCordsRef.current.x,
            initialCordsRef.current.y
        );

        setInitialCords({
            x: x,
            y: y,
        });

        drawCtxRef.current.lineTo(x, y);

        drawCtxRef.current.stroke();
    };

    const erase = ({ x, y }: { x: number; y: number }) => {
        if (!drawCtxRef.current) {
            return;
        }

        drawCtxRef.current.beginPath();

        drawCtxRef.current.lineWidth = eraserSizeRef.current;
        drawCtxRef.current.lineCap = 'round';
        drawCtxRef.current.globalCompositeOperation = 'destination-out';

        drawCtxRef.current.moveTo(
            initialCordsRef.current.x,
            initialCordsRef.current.y
        );

        setInitialCords({
            x: x,
            y: y,
        });

        drawCtxRef.current.lineTo(x, y);

        drawCtxRef.current.stroke();

        drawCtxRef.current.globalCompositeOperation = 'source-over';
    };

    const handleClickSaveWhiteboard = async () => {
        if (!canvasRef.current) {
            return;
        }

        const linkanchor = document.createElement('a');

        const canvasCopy = document.createElement('canvas');

        canvasCopy.width = CANVAS_MAX_WIDTH;
        canvasCopy.height = CANVAS_MAX_HEIGHT;

        const canvasCopyCtx = canvasCopy.getContext('2d');

        if (!canvasCopyCtx) {
            return;
        }

        canvasCopyCtx.fillStyle = 'white';
        canvasCopyCtx.fillRect(0, 0, CANVAS_MAX_WIDTH, CANVAS_MAX_HEIGHT);

        const imagesArray = Object.values(uploadedImagesRef.current);
        for (let idx = 0; idx < imagesArray.length; idx++) {
            let image = imagesArray[idx];
            let img = new Image();
            img.setAttribute('crossorigin', 'anonymous');
            let url = `${baseApiURL}file/${whiteboardId}/${image.uuid}`;

            // a promise that fulfills once an image is loaded
            await new Promise((r) => {
                img.onload = r;
                img.src = url;
            });

            canvasCopyCtx.drawImage(
                img,
                image.x,
                image.y,
                image.width,
                image.height
            );
        }

        canvasCopyCtx.drawImage(canvasRef.current, 0, 0);

        canvasCopy.toBlob((blob) => {
            if (!blob) {
                return;
            }

            linkanchor.href = URL.createObjectURL(blob);
            linkanchor.target = '_blank';
            linkanchor.click();
        });
    };

    const handleRemoveImage = (imageUUID: string) => {
        removeUploadedImage(imageUUID);

        setSyncQueue((syncQueue) => {
            return [
                ...syncQueue,
                {
                    tool: 'removeImage',
                    x: 0,
                    y: 0,
                    settings: {
                        uuid: imageUUID,
                    },
                },
            ];
        });
    };

    const handleChangeImageSize = (image: UploadedImage, size: number) => {
        addUploadedImage({
            ...image,
            width: size as number,
            height: image.height / (image.width / (size as number)),
        });
        setSyncQueue((syncQueue) => {
            return [
                ...syncQueue,
                {
                    tool: 'addImage',
                    x: 0,
                    y: 0,
                    settings: {
                        uuid: image.uuid,
                        x: image.x,
                        y: image.y,
                        width: size as number,
                        height: image.height / (image.width / (size as number)),
                    },
                },
            ];
        });
    };

    const handleMoveImageMouseDown = (
        e: React.MouseEvent<HTMLImageElement, MouseEvent>
    ) => {
        e.currentTarget.style.cursor = 'grabbing';
        e.preventDefault();
        return false;
    };

    const handleMoveImageMouseUp = (
        e: React.MouseEvent<HTMLImageElement, MouseEvent>,
        image: UploadedImage
    ) => {
        e.currentTarget.style.cursor = 'grab';
        setSyncQueue((syncQueue) => {
            if (!canvasContainer.current) {
                return syncQueue;
            }

            return [
                ...syncQueue,
                {
                    tool: 'addImage',
                    x: 0,
                    y: 0,
                    settings: {
                        uuid: image.uuid,
                        x: image.x + e.movementX,
                        y: image.y + e.movementY,
                        width: image.width,
                        height: image.height,
                    },
                },
            ];
        });
    };

    const handleMoveImageMouseLeave = (
        e: React.MouseEvent<HTMLImageElement, MouseEvent>,
        image: UploadedImage
    ) => {
        e.currentTarget.style.cursor = 'grab';
        setSyncQueue((syncQueue) => {
            return [
                ...syncQueue,
                {
                    tool: 'addImage',
                    x: 0,
                    y: 0,
                    settings: {
                        uuid: image.uuid,
                        x: image.x + e.movementX,
                        y: image.y + e.movementY,
                        width: image.width,
                        height: image.height,
                    },
                },
            ];
        });
    };

    const handleMoveImageMouseMove = (
        e: React.MouseEvent<HTMLImageElement, MouseEvent>,
        image: UploadedImage
    ) => {
        if (e.buttons !== 1) {
            return;
        }
        _setUploadedImages((uploadedImages) => {
            const newImages = {
                ...uploadedImages,
                [image.uuid]: {
                    ...image,
                    x: image.x + e.movementX,
                    y: image.y + e.movementY,
                },
            };
            uploadedImagesRef.current = newImages;
            return newImages;
        });
    };

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: zIndexes.whiteboardLayer,
                width: '100%',
                height: '100vh',
                backgroundColor: 'white',
                display: 'flex',
            }}
        >
            <Typography
                color="secondary"
                variant="caption"
                style={{
                    position: 'absolute',
                    right: 0,
                }}
            >
                x: {initialCords.x} y: {initialCords.y}
            </Typography>
            <Paper
                elevation={3}
                sx={{
                    position: 'absolute',
                    zIndex: zIndexes.menuLayer,
                }}
            >
                <IconButton
                    color={selectedTool === 'move' ? 'primary' : 'secondary'}
                    onClick={() => {
                        setSelectedTool('move');
                    }}
                >
                    <PanToolIcon />
                </IconButton>
                <IconButton
                    color={selectedTool === 'pencil' ? 'primary' : 'secondary'}
                    onClick={(e) => {
                        if (selectedTool === 'pencil') {
                            setPencilMenuAnchor(e.currentTarget);
                        }

                        setSelectedTool('pencil');
                    }}
                >
                    <ModeIcon />
                </IconButton>
                <Menu
                    open={!!pencilMenuAnchor}
                    anchorEl={pencilMenuAnchor}
                    onClose={() => {
                        setPencilMenuAnchor(null);
                    }}
                    transitionDuration={0}
                    sx={{
                        zIndex: zIndexes.menuOverlay,
                    }}
                >
                    <Box
                        sx={{
                            pl: 2,
                            pr: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 3,
                            }}
                        >
                            <Slider
                                sx={{ minWidth: 70 }}
                                size="small"
                                value={pencilSize}
                                onChange={(_, value) => {
                                    setPencilSize(value as number);
                                }}
                                min={1}
                                max={50}
                            />
                            <Box
                                sx={{
                                    minWidth: 51,
                                    minHeight: 51,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: pencilSize + 1,
                                        height: pencilSize + 1,
                                        flexShrink: 0,
                                        backgroundColor: pencilColor,
                                        borderRadius: '50%',
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 1,
                            }}
                        >
                            {AVAILABLE_COLORS.map((color) => (
                                <Box
                                    key={color}
                                    onClick={() => {
                                        setPencilColor(color);
                                    }}
                                    component="a"
                                    sx={{
                                        width: 15,
                                        height: 15,
                                        border: '2px solid gray',
                                        display: 'inline-block',
                                        marginRight: 1,
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                        backgroundColor: color,
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Menu>
                <IconButton
                    color={selectedTool === 'eraser' ? 'primary' : 'secondary'}
                    onClick={(e) => {
                        if (selectedTool === 'eraser') {
                            setEraserMenuAnchor(e.currentTarget);
                        }
                        setSelectedTool('eraser');
                    }}
                >
                    {/* egh one icon was not in MUI icons so we need to hard code it */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="m16.24 3.56l4.95 4.94c.78.79.78 2.05 0 2.84L12 20.53a4.008 4.008 0 0 1-5.66 0L2.81 17c-.78-.79-.78-2.05 0-2.84l10.6-10.6c.79-.78 2.05-.78 2.83 0M4.22 15.58l3.54 3.53c.78.79 2.04.79 2.83 0l3.53-3.53l-4.95-4.95l-4.95 4.95Z"
                        />
                    </svg>
                </IconButton>
                <Menu
                    open={!!eraserMenuAnchor}
                    anchorEl={eraserMenuAnchor}
                    onClose={() => {
                        setEraserMenuAnchor(null);
                    }}
                    transitionDuration={0}
                    sx={{
                        zIndex: zIndexes.menuOverlay,
                    }}
                >
                    <Box
                        sx={{
                            pl: 2,
                            pr: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 3,
                            }}
                        >
                            <Slider
                                sx={{ minWidth: 70 }}
                                size="small"
                                value={eraserSize}
                                onChange={(_, value) => {
                                    setEraserSize(value as number);
                                }}
                                min={1}
                                max={50}
                            />
                            <Box
                                sx={{
                                    minWidth: 51,
                                    minHeight: 51,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: eraserSize + 1,
                                        height: eraserSize + 1,
                                        flexShrink: 0,
                                        border: '1px solid black',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Menu>
                <IconButton
                    color="secondary"
                    onClick={() => {
                        uploadImageInputRef.current?.click();
                    }}
                >
                    <DriveFolderUploadOutlinedIcon />
                </IconButton>
                <input
                    ref={uploadImageInputRef}
                    type="file"
                    accept="image/*"
                    hidden
                />
                <IconButton
                    color={
                        selectedTool === 'moveMedia' ? 'primary' : 'secondary'
                    }
                    onClick={() => {
                        setSelectedTool('moveMedia');
                    }}
                >
                    <SwipeVerticalIcon />
                </IconButton>
                <IconButton
                    color="secondary"
                    onClick={handleClickSaveWhiteboard}
                >
                    <SaveOutlinedIcon />
                </IconButton>
            </Paper>
            <Box
                ref={canvasContainer}
                sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                <canvas
                    width={CANVAS_MAX_WIDTH}
                    height={CANVAS_MAX_HEIGHT}
                    style={{
                        width: CANVAS_MAX_WIDTH,
                        height: CANVAS_MAX_HEIGHT,
                        zIndex: zIndexes.drawingLayer,
                        position: 'relative',
                        top: 0,
                        left: 0,
                        border: '2px dotted gray',
                    }}
                    ref={canvasRef}
                />
                <Box
                    sx={{
                        width: CANVAS_MAX_WIDTH,
                        height: CANVAS_MAX_HEIGHT,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex:
                            selectedTool === 'moveMedia'
                                ? zIndexes.aboveDrawingLayer
                                : zIndexes.belowDrawingLayer,
                    }}
                >
                    {Object.values(uploadedImages).map((image) => (
                        <div
                            key={image.uuid}
                            style={{
                                position: 'absolute',
                                top: image.y,
                                left: image.x,
                                width: image.width,
                                height: image.height,
                                userSelect: 'none',
                                cursor: 'grab',
                            }}
                        >
                            {selectedTool === 'moveMedia' && (
                                <Paper
                                    elevation={3}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: 350,
                                        borderRadius: 0,
                                    }}
                                >
                                    <Stack
                                        spacing={2}
                                        direction="row"
                                        sx={{ mb: 1, mr: 2 }}
                                        alignItems="center"
                                    >
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                handleRemoveImage(image.uuid);
                                            }}
                                        >
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                        <ZoomOutOutlinedIcon />
                                        <Slider
                                            aria-label="Volume"
                                            value={image.width}
                                            min={10}
                                            max={UPLOADED_MEDIA_MAX_WIDTH}
                                            onChange={(_, value) => {
                                                handleChangeImageSize(
                                                    image,
                                                    value as number
                                                );
                                            }}
                                        />
                                        <ZoomInOutlinedIcon />
                                    </Stack>
                                </Paper>
                            )}
                            <img
                                src={`${baseApiURL}file/${whiteboardId}/${image.uuid}`}
                                alt="user-uploaded-content"
                                onMouseDown={handleMoveImageMouseDown}
                                onMouseUp={(e) =>
                                    handleMoveImageMouseUp(e, image)
                                }
                                onMouseLeave={(e) =>
                                    handleMoveImageMouseLeave(e, image)
                                }
                                onMouseMove={(e) =>
                                    handleMoveImageMouseMove(e, image)
                                }
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </div>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
export default Whiteboard;
