/* eslint-disable react-hooks/exhaustive-deps*/
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import JoinCallRoom from '../../components/JoinCallRoom';
import { useEffect, useState } from 'react';
import CallRoom from '../../components/CallRoom';
import apiClient from '../../apiClient/ApiClient';
import { CircularProgress } from '@mui/material';
import useSettingsContext from '../../contexts/SettingsContext';
import useRoomData from '../../contexts/RoomDataContext';
import useLocalMediaStreamContext from '../../contexts/LocalMediaStreamContext';
import useSelectedDevices from '../../contexts/SelectedDevicesContext';

const CallPage = () => {
    const navigate = useNavigate();
    const settings = useSettingsContext();
    const { setRoomData, roomData } = useRoomData();
    const { setLocalMediaStream, localMediaStream } =
        useLocalMediaStreamContext();

    const { uuid } = useParams();
    const [query] = useSearchParams();

    const [joinedCall, setJoinedCall] = useState(false);

    const selectedDevices = useSelectedDevices();

    useEffect(() => {
        if (uuid) {
            apiClient.getRoomData(uuid).then((data) => {
                if (!data) {
                    navigate('/404?eVisitNotFound=true');
                    return;
                }
                setRoomData(data);
            });

            if (query.get('name') && !settings?.get('display_name')) {
                settings?.set('display_name', query.get('name') as string);
            }
        } else {
            navigate('/404');
            return;
        }
    }, []);

    useEffect(() => {
        // try clearing old selected device
        if (localMediaStream) {
            localMediaStream.getTracks().forEach((track) => track.stop());
        }

        settings?.set('cameraId', selectedDevices.cameraId || '');
        settings?.set('microphoneId', selectedDevices.microphoneId || '');
        settings?.set('facingMode', selectedDevices.facingMode || '');

        const constraints: MediaStreamConstraints = {
            video: {
                deviceId: selectedDevices.cameraId as string,
                facingMode: selectedDevices.facingMode,
            },
            audio: {
                deviceId: selectedDevices.microphoneId as string,
                facingMode: selectedDevices.facingMode,
            },
        };

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((mediaStream) => {
                setLocalMediaStream(mediaStream);
            })
            .catch((e) => {
                // on first err try getting just camera
                const constraintsVideoOnly: MediaStreamConstraints = {};
                constraintsVideoOnly.video = constraints.video;

                navigator.mediaDevices
                    .getUserMedia(constraintsVideoOnly)
                    .then((mediaStream) => {
                        setLocalMediaStream(mediaStream);
                    })
                    .catch((e) => {
                        // on second err try getting just audio

                        const constraintsOnlyAudio: MediaStreamConstraints = {};
                        constraintsOnlyAudio.audio = constraints.audio;

                        navigator.mediaDevices
                            .getUserMedia(constraintsOnlyAudio)
                            .then((mediaStream) => {
                                setLocalMediaStream(mediaStream);
                            });
                    });
            });
    }, [
        selectedDevices.cameraId,
        selectedDevices.microphoneId,
        selectedDevices.facingMode,
    ]);

    if (!roomData) {
        return (
            <DefaultLayout>
                <CircularProgress />
            </DefaultLayout>
        );
    }

    return (
        <DefaultLayout>
            {(!joinedCall || !localMediaStream) && (
                <JoinCallRoom
                    onJoin={() => {
                        setJoinedCall(true);
                    }}
                />
            )}
            {joinedCall && !!localMediaStream && !!uuid && (
                <CallRoom
                    localMediaStream={localMediaStream}
                    roomData={roomData}
                />
            )}
        </DefaultLayout>
    );
};

export default CallPage;
