import { UUID } from 'crypto';
import {
    AnswerResponse,
    ChatMessageRequestType,
    ChatMessageType,
    ChatSyncRequest,
    ChatSyncResponse,
    IceResponse,
    LoginResponse,
    MetaRequest,
    MetaResponse,
    OfferResponse,
    ScreenshareRequest,
    ScreenshareResponse,
    UserJoinResponse,
    UserLeftResponse,
    WhoHasChatResponse,
    WsApiResponse,
    afterLoginHandlerType,
    onAnswerType,
    onChatMessageType,
    onChatSyncType,
    onErrorType,
    onIceType,
    onMetaType,
    onOfferType,
    onOpenHandlerType,
    onScreenshareStartType,
    onScreenshareStopType,
    onUserJoinType,
    onUserLeftType,
    onWhoHasChatType,
    onWsCloseType,
} from './WsClient.types';

const wsUrl =
    process.env.NODE_ENV === 'production'
        ? 'wss://ewizyta.igabinet.com:8100/ws'
        : 'wss://localhost:8100/ws';

class WsClient {
    wsConn: WebSocket;
    afterLoginHandler: afterLoginHandlerType | undefined;
    onUserJoin: onUserJoinType | undefined;
    onUserLeft: onUserLeftType | undefined;
    onOffer: onOfferType | undefined;
    onAnswer: onAnswerType | undefined;
    onIce: onIceType | undefined;
    onMeta: onMetaType | undefined;
    onError: onErrorType | undefined;
    onChat: onChatMessageType | undefined;
    onChatSync: onChatSyncType | undefined;
    onWhoHasChat: onWhoHasChatType | undefined;
    onWsClose: onWsCloseType | undefined;
    onScreenshareStart: onScreenshareStartType | undefined;
    onScreenshareStop: onScreenshareStopType | undefined;
    // a simple bool to check if onClose event is initiated by client or server side
    shouldBeClosed: boolean;

    constructor({
        onOpenHandler,
        afterLoginHandler,
        onUserJoin,
        onUserLeft,
        onOffer,
        onAnswer,
        onIce,
        onMeta,
        onError,
        onChat,
        onChatSync,
        onWhoHasChat,
        onWsClose,
        onScreenshareStart,
        onScreenshareStop,
    }: {
        onOpenHandler?: onOpenHandlerType;
        afterLoginHandler?: afterLoginHandlerType;
        onUserJoin?: onUserJoinType;
        onUserLeft?: onUserLeftType;
        onOffer?: onOfferType;
        onAnswer?: onAnswerType;
        onIce?: onIceType;
        onMeta?: onMetaType;
        onError?: onErrorType;
        onChat?: onChatMessageType;
        onChatSync?: onChatSyncType;
        onWhoHasChat?: onWhoHasChatType;
        onWsClose?: onWsCloseType;
        onScreenshareStart?: onScreenshareStartType;
        onScreenshareStop?: onScreenshareStopType;
    }) {
        this.wsConn = new WebSocket(wsUrl);
        this.afterLoginHandler = afterLoginHandler;
        this.onUserJoin = onUserJoin;
        this.onUserLeft = onUserLeft;
        this.onOffer = onOffer;
        this.onAnswer = onAnswer;
        this.onIce = onIce;
        this.onMeta = onMeta;
        this.onError = onError;
        this.onChat = onChat;
        this.onChatSync = onChatSync;
        this.onWhoHasChat = onWhoHasChat;
        this.onWsClose = onWsClose;
        this.onScreenshareStart = onScreenshareStart;
        this.onScreenshareStop = onScreenshareStop;
        this.shouldBeClosed = false;

        if (onOpenHandler) {
            this.wsConn.addEventListener('open', onOpenHandler);
        }

        if (this.onError) {
            this.wsConn.addEventListener('error', this.onError);
        }

        if (this.onWsClose) {
            this.wsConn.addEventListener('close', this.onWsClose);
        }

        this.wsConn.addEventListener('message', (event) => {
            try {
                const data: WsApiResponse<
                    | LoginResponse
                    | UserLeftResponse
                    | UserJoinResponse
                    | OfferResponse
                    | AnswerResponse
                    | IceResponse
                    | MetaResponse
                    | ChatSyncResponse
                    | ChatMessageType
                    | WhoHasChatResponse
                    | ScreenshareResponse
                > = JSON.parse(event.data);

                if (data.code !== 0) {
                    throw new Error('api error recieved code: ' + data.code);
                }

                switch (data.action) {
                    case 'login': {
                        if (this.afterLoginHandler) {
                            this.afterLoginHandler(data.data as LoginResponse);
                        }
                        break;
                    }
                    case 'userJoin': {
                        if (this.onUserJoin) {
                            this.onUserJoin(data.data as UserJoinResponse);
                        }
                        break;
                    }
                    case 'userLeft': {
                        if (this.onUserLeft) {
                            this.onUserLeft(data.data as UserLeftResponse);
                        }
                        break;
                    }
                    case 'offer': {
                        if (this.onOffer) {
                            this.onOffer(data.data as OfferResponse);
                        }
                        break;
                    }
                    case 'answer': {
                        if (this.onAnswer) {
                            this.onAnswer(data.data as AnswerResponse);
                        }
                        break;
                    }
                    case 'ice': {
                        if (this.onIce) {
                            this.onIce(data.data as IceResponse);
                        }
                        break;
                    }
                    case 'meta': {
                        if (this.onMeta) {
                            this.onMeta(data.data as MetaResponse);
                        }
                        break;
                    }
                    case 'chat': {
                        if (this.onChat) {
                            this.onChat(data.data as ChatMessageType);
                        }
                        break;
                    }
                    case 'chatSync': {
                        if (this.onChatSync) {
                            this.onChatSync(data.data as ChatSyncResponse);
                        }
                        break;
                    }
                    case 'whoHasChat': {
                        if (this.onWhoHasChat) {
                            this.onWhoHasChat(data.data as WhoHasChatResponse);
                        }
                        break;
                    }
                    case 'startScreenShare': {
                        if (this.onScreenshareStart) {
                            this.onScreenshareStart(
                                data.data as ScreenshareResponse
                            );
                        }
                        break;
                    }
                    case 'stopScreenShare': {
                        if (this.onScreenshareStop) {
                            this.onScreenshareStop(
                                data.data as ScreenshareResponse
                            );
                        }
                        break;
                    }
                }
            } catch (e) {
                console.warn(e);
                return;
            }
        });
    }

    public login(selfUuid: UUID, roomId: UUID, displayName: string) {
        const request = {
            action: 'login',
            payload: {
                uuid: selfUuid,
                room_id: roomId,
                display_name: displayName,
            },
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public sendOffer(to: UUID, offer: RTCSessionDescriptionInit) {
        const request = {
            action: 'offer',
            payload: {
                recipient: to,
                offer: offer,
            },
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public sendAnswer(to: UUID, answer: RTCSessionDescriptionInit) {
        const request = {
            action: 'answer',
            payload: {
                recipient: to,
                answer: answer,
            },
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public sendIce(to: UUID, candidate: RTCIceCandidate) {
        const request = {
            action: 'ice',
            payload: {
                recipient: to,
                candidate: candidate,
            },
        };

        this.wsConn.send(JSON.stringify(request));
    }

    public sendMeta(meta: MetaRequest) {
        const request = {
            action: 'meta',
            payload: meta,
        };
        this.wsConn.send(JSON.stringify(request));
    }

    public sendChatMessage(message: ChatMessageRequestType) {
        const request = {
            action: 'chat',
            payload: message,
        };
        this.wsConn.send(JSON.stringify(request));
    }

    public sendChatSyncRequest() {
        const request = {
            action: 'whoHasChat',
        };
        this.wsConn.send(JSON.stringify(request));
    }

    public sendChatSyncMessage(data: ChatSyncRequest) {
        const request = {
            action: 'chatSync',
            payload: data,
        };
        this.wsConn.send(JSON.stringify(request));
    }

    public sendStartScreenshareRequest(data: ScreenshareRequest) {
        const request = {
            action: 'startScreenShare',
            payload: data,
        };
        this.wsConn.send(JSON.stringify(request));
    }

    public sendStopScreenshareRequest(data: ScreenshareRequest) {
        const request = {
            action: 'stopScreenShare',
            payload: data,
        };
        this.wsConn.send(JSON.stringify(request));
    }

    public close() {
        this.shouldBeClosed = true;
        this.wsConn.close();
    }
}
export default WsClient;
