import { useState } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useLanguagepack from '../languagepack/Languagepack';

const COOKIES_POPUP_LOCAL_STORAGE_KEY = 'cookiesAccepted';
const COOKIES_POPUP_ID = '2023-07-27';

export default function CookiesPopup() {
    const languagepack = useLanguagepack();

    const [open, setOpen] = useState<boolean>(showPopupIfNotNotAccepted());

    function showPopupIfNotNotAccepted() {
        const isPopupNotAccepted =
            localStorage.getItem(COOKIES_POPUP_LOCAL_STORAGE_KEY) !==
            COOKIES_POPUP_ID;

        if (isPopupNotAccepted) {
            localStorage.removeItem(COOKIES_POPUP_LOCAL_STORAGE_KEY);
            return true;
        }

        return false;
    }

    function handleClose() {
        localStorage.setItem(COOKIES_POPUP_LOCAL_STORAGE_KEY, COOKIES_POPUP_ID);
        setOpen(false);
    }

    return (
        <div>
            <Snackbar
                open={open}
                onClose={handleClose}
                message={languagepack.cookiesPopupContent}
                ClickAwayListenerProps={{ onClickAway: () => null }}
                action={
                    <IconButton color="info" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
        </div>
    );
}
