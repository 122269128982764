import {
    Box,
    CircularProgress,
    Drawer,
    DrawerProps,
    IconButton,
    Portal,
    TextField,
    Tooltip,
} from '@mui/material';
import WsClient from '../wsClient/WsClient';
import { ChatMessageType } from '../wsClient/WsClient.types';
import ChatMessage from './ChatMessage';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useFormik } from 'formik';
import { ChangeEvent, KeyboardEvent, createRef, useMemo } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Close } from '@mui/icons-material';
import useLanguagepack from '../languagepack/Languagepack';
import useSnackbar from '../snackbarService/SnackbarService';
import apiClient from '../apiClient/ApiClient';
import useSelfUserUuid from '../contexts/SelfUserUuidContext';
import useRoomData from '../contexts/RoomDataContext';
import useSettingsContext from '../contexts/SettingsContext';

const ChatDrawer = ({
    open,
    onClose,
    wsClient,
    chatMessages,
    userDisplayNames,
}: {
    open: boolean;
    onClose: VoidFunction;
    wsClient?: WsClient;
    chatMessages: { [key: string]: ChatMessageType };
    userDisplayNames: { [key: string]: string };
}) => {
    const fileInputRef = createRef<HTMLInputElement>();
    const languagePack = useLanguagepack();
    const selfUserId = useSelfUserUuid();
    const showSnackbar = useSnackbar();
    const { roomData } = useRoomData();
    const settings = useSettingsContext();

    const chatMessagesSorted = useMemo(() => {
        return Object.values(chatMessages).sort(
            (a, b) =>
                new Date(a.sent_at).getTime() - new Date(b.sent_at).getTime()
        );
    }, [chatMessages]);

    const formik = useFormik({
        initialValues: inittialValues,
        onSubmit: (values) => {
            if (values.content === '') {
                return;
            }

            wsClient?.sendChatMessage({
                content: values.content,
                content_type: values.content_type,
            });

            formik.setFieldValue('content', '');
            formik.setFieldValue('content_type', 'text/chat');
        },
    });

    const anchorPoint =
        (settings?.get('chat_anchor') as DrawerProps['anchor']) || 'right';

    if (!roomData) {
        return <CircularProgress />;
    }

    const hadleFileSelect = async (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        if (target.files?.[0]) {
            // limit upload size
            if (target.files[0].size > 10 * 1024 * 1024) {
                showSnackbar(languagePack.uploadSizeLimit);
                return;
            }

            const response = await apiClient.uploadFile(
                roomData.uuid,
                target.files[0]
            );

            if (response) {
                wsClient?.sendChatMessage({
                    content: response.uuid,
                    content_type: target.files[0].type || 'unknown',
                    filename: target.files[0].name,
                });
            } else {
                showSnackbar(languagePack.uploadFailed);
            }
        }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            formik.handleSubmit();
        }
    };

    return (
        <Portal>
            <Drawer anchor={anchorPoint} open={open} onClose={onClose}>
                <Box>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '100%',
                        minHeight: 0,
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            flexDirection: 'column',
                            gap: 1,
                            maxWidth: 350,
                            overflowY: 'scroll',
                            padding: 2,
                        }}
                    >
                        {chatMessagesSorted.map((message) => (
                            <ChatMessage
                                key={message.sent_at}
                                isYourOwnMessage={message.sender === selfUserId}
                                chatMessage={message}
                                userDisplayNames={userDisplayNames}
                                roomId={roomData.uuid}
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: 1,
                            padding: 2,
                        }}
                    >
                        <TextField
                            name="content"
                            label={languagePack.message}
                            value={formik.values.content || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyUp={handleKeyUp}
                            variant="standard"
                            multiline
                            sx={{
                                flex: 1,
                            }}
                        />
                        <Tooltip
                            title={
                                !!roomData.visit_start
                                    ? languagePack.attachment
                                    : languagePack.noFileUploadsForReservations
                            }
                        >
                            <span style={{display: 'flex'}}>
                                <IconButton
                                    component="label"
                                    disabled={!roomData.visit_start}
                                >
                                    <AttachFileIcon />
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        hidden
                                        onChange={(e) => {
                                            hadleFileSelect(e);
                                        }}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={languagePack.send}>
                            <IconButton
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                color="primary"
                            >
                                <SendOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Drawer>
        </Portal>
    );
};

const inittialValues = {
    content: '',
    content_type: 'text/chat',
};

export default ChatDrawer;
