/* eslint-disable react-hooks/exhaustive-deps*/
import {
    Box,
    Button,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { createRef, useEffect, useMemo, useState } from 'react';
import useLanguagepack from '../languagepack/Languagepack';
import useSettingsContext from '../contexts/SettingsContext';
import * as yup from 'yup';
import { useFormik } from 'formik';
import SettingsIcon from '@mui/icons-material/Settings';

import CameraOffIcon from '@mui/icons-material/NoPhotography';
import CameraOnIcon from '@mui/icons-material/CameraAlt';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import useRoomData from '../contexts/RoomDataContext';
import useLocalMediaStreamContext from '../contexts/LocalMediaStreamContext';
import SettingsModal from './SettingsModal';

const JoinCallRoom = ({ onJoin }: { onJoin: () => void }) => {
    const languagepack = useLanguagepack();
    const callSettings = useSettingsContext();
    const theme = useTheme();
    const { roomData } = useRoomData();
    const { localMediaStream } = useLocalMediaStreamContext();
    const isSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isSizeMd = useMediaQuery(theme.breakpoints.down('md'));
    const [settingsOpen, setSettingsOpen] = useState(false);

    const videoPreviewRef = createRef<HTMLVideoElement>();

    const initialValues = {
        display_name: callSettings?.get('display_name') || '',
    };

    const valdiationSchema = yup.object({
        display_name: yup.string().required(languagepack.displayNameRequired),
    });

    const cameraOff = callSettings?.get('camera_off');

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: valdiationSchema,
        onSubmit: (values) => {
            callSettings?.set('display_name', values.display_name);
            onJoin();
        },
    });

    const updateVideoPlaybackRef = (
        localMediaStream: MediaStream | null,
        videoPreview: HTMLVideoElement
    ) => {
        if (localMediaStream) {
            if (localMediaStream.getVideoTracks()[0]) {
                const videoStreamSettings = localMediaStream
                    .getVideoTracks()[0]
                    .getSettings();
                const videoAspectRatio =
                    (videoStreamSettings.width ?? 1) /
                    (videoStreamSettings.height ?? 1);
                videoPreview.style.aspectRatio = videoAspectRatio.toString();
                videoPreview.style.backgroundColor = '';
            }

            videoPreview.volume = 0;
            videoPreview.srcObject = localMediaStream;
            videoPreview.play().catch((e) => {
                console.log('play failed - probably changed user media device');
            });
        }
    };

    useEffect(() => {
        if (!videoPreviewRef.current) {
            return;
        }

        if (cameraOff) {
            videoPreviewRef.current.srcObject = null;
            videoPreviewRef.current.style.backgroundColor = 'black';
        } else {
            updateVideoPlaybackRef(localMediaStream, videoPreviewRef.current);
        }
    }, [localMediaStream, cameraOff]);

    const microphoneMuted = callSettings?.get('microphone_muted');

    const handleClickMicrophone = () => {
        callSettings?.set('microphone_muted', !microphoneMuted);
    };

    const handleClickCamera = () => {
        callSettings?.set('camera_off', !cameraOff);
    };

    const videoUnavailable = useMemo(() => {
        return !localMediaStream?.getVideoTracks().length;
    }, [localMediaStream]);

    const audioUnavailable = useMemo(() => {
        return !localMediaStream?.getAudioTracks().length;
    }, [localMediaStream]);

    const makeBgLogoStyle = (): React.CSSProperties => {
        if (isSizeSm) {
            return {
                position: 'absolute',
                pointerEvents: 'none',
                opacity: 0.2,
                width: '70%',
                zIndex: -1,
                top: 0,
                marginRight: 'auto',
            };
        } else {
            return {
                position: 'absolute',
                pointerEvents: 'none',
                opacity: 0.2,
                height: 200,
                zIndex: -1,
                bottom: 0,
                right: 0,
            };
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                flexWrap: 'wrap',
                maxWidth: '100%',
            }}
        >
            <img
                src="/igabinet_logo.svg"
                alt="igabinet logo"
                style={makeBgLogoStyle()}
            />
            <Box
                sx={{
                    maxWidth: 450,
                }}
            >
                <video
                    ref={videoPreviewRef}
                    autoPlay={true}
                    controls={false}
                    playsInline={true}
                    muted={true}
                    style={{
                        width: '100%',
                        maxWidth: '100%',
                        maxHeight: isSizeMd ? 200 : 400,
                        backgroundColor: 'black',
                        aspectRatio: 16 / 9,
                        borderRadius: 10,
                    }}
                    poster="/cameraOff.svg"
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        justifyContent: 'center',
                    }}
                >
                    <Tooltip
                        title={
                            audioUnavailable
                                ? languagepack.noMediaDevice
                                : languagepack.mute
                        }
                    >
                        <span>
                            <IconButton
                                size="large"
                                color={microphoneMuted ? 'error' : 'primary'}
                                disabled={audioUnavailable}
                                onClick={handleClickMicrophone}
                            >
                                {!microphoneMuted && <MicIcon />}
                                {microphoneMuted && <MicOffIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={
                            videoUnavailable
                                ? languagepack.noMediaDevice
                                : languagepack.camera
                        }
                    >
                        <span>
                            <IconButton
                                size="large"
                                onClick={handleClickCamera}
                                disabled={videoUnavailable}
                                color={cameraOff ? 'error' : 'primary'}
                            >
                                {!cameraOff && <CameraOnIcon />}
                                {cameraOff && <CameraOffIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={
                            !localMediaStream
                                ? languagepack.noMediaDevice
                                : languagepack.settings
                        }
                    >
                        <span>
                            <IconButton
                                size="large"
                                disabled={!localMediaStream}
                                onClick={() => {
                                    setSettingsOpen(true);
                                }}
                                sx={{
                                    color: 'gray',
                                }}
                            >
                                <SettingsIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography>
                    {languagepack.meetingWith}: {roomData?.doctors_name}
                </Typography>

                <TextField
                    name="display_name"
                    label={languagepack.displayName}
                    variant="standard"
                    value={formik.values.display_name}
                    onBlur={formik.handleBlur}
                    error={
                        !!formik.touched.display_name &&
                        !!formik.errors.display_name
                    }
                    helperText={
                        !!formik.touched.display_name &&
                        formik.errors.display_name
                    }
                    onChange={formik.handleChange}
                />
                <Tooltip
                    title={!localMediaStream ? languagepack.noMediaDevice : ''}
                >
                    <span style={{ flex: 1 }}>
                        <Button
                            variant="contained"
                            disabled={!localMediaStream}
                            fullWidth
                            onClick={() => {
                                formik.handleSubmit();
                            }}
                        >
                            {languagepack.join}
                        </Button>
                    </span>
                </Tooltip>
            </Box>
            {!!localMediaStream && (
                <SettingsModal
                    open={settingsOpen}
                    onClose={() => {
                        setSettingsOpen(false);
                    }}
                />
            )}
        </Box>
    );
};

export default JoinCallRoom;
