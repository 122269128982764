import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

const WhiteboardSettingsContext = createContext<{
    set: (setting: string, value: string | number | boolean) => void;
    get: (setting: string) => string | number | boolean;
    settings: { [key: string]: string | number | boolean };
} | null>(null);

export const WhiteboardSettingsContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [settings, setSettings] = useState<{
        [key: string]: string | number | boolean;
    }>(JSON.parse(localStorage.getItem('whiteboardSettings') || '{}'));

    useEffect(() => {
        localStorage.setItem('whiteboardSettings', JSON.stringify(settings));
    }, [settings]);

    return (
        <WhiteboardSettingsContext.Provider
            value={{
                set: (setting: string, value: string | number | boolean) => {
                    setSettings((settings) => ({
                        ...settings,
                        [setting]: value,
                    }));
                },
                get: (setting: string) => {
                    return settings[setting as keyof typeof settings];
                },
                settings: settings,
            }}
        >
            {children}
        </WhiteboardSettingsContext.Provider>
    );
};

const useWhiteboardSettingsContext = () => {
    const whiteboardSettingsContext = useContext(WhiteboardSettingsContext);

    return whiteboardSettingsContext;
};
export default useWhiteboardSettingsContext;
