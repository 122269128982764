import { FormControlLabel, Checkbox } from '@mui/material';
import useLanguagepack from '../languagepack/Languagepack';
import Confirm from './Confirm';
import { useState } from 'react';
import useSettingsContext from '../contexts/SettingsContext';

const ConfirmScreenshare = ({
    dialogOpen,
    onConfirm,
    onCancel,
}: {
    dialogOpen: boolean;
    onConfirm?: VoidFunction;
    onCancel?: VoidFunction;
}) => {
    const languagepack = useLanguagepack();
    const settings = useSettingsContext();
    const [dontShowAgain, setDontShowAgain] = useState(
        !!settings?.get('screenshare_notice_dont_show_again')
    );

    return (
        <Confirm
            open={dialogOpen}
            header={languagepack.youreAboutToScreenshare}
            text={languagepack.screenshareNotice}
            onConfirm={onConfirm}
            onCancel={onCancel}
            additional={
                <FormControlLabel
                    sx={{
                        color: 'gray',
                    }}
                    control={
                        <Checkbox
                            size="small"
                            checked={dontShowAgain}
                            onChange={(e) => {
                                settings?.set(
                                    'screenshare_notice_dont_show_again',
                                    e.target.checked
                                );
                                setDontShowAgain(e.target.checked);
                            }}
                        />
                    }
                    label={languagepack.dontShowAgain}
                />
            }
        />
    );
};
export default ConfirmScreenshare;
