import { Box, Typography } from '@mui/material';
import DefaultLayout from '../layouts/DefaultLayout';
import useLanguagepack from '../languagepack/Languagepack';
import { useSearchParams } from 'react-router-dom';

const Error404Page = () => {
    const [query] = useSearchParams();
    const isEVisitNotFound = !!query.get('eVisitNotFound');

    const languagepack = useLanguagepack();

    return (
        <DefaultLayout>
            <Box
                sx={{
                    textAlign: 'center',
                }}
            >
                <img
                    src="/igabinet_logo.svg"
                    alt="igabinet logo"
                    style={{
                        position: 'absolute',
                        pointerEvents: 'none',
                        opacity: 0.2,
                        width: '40%',
                        zIndex: -1,
                        bottom: 0,
                        right: 0,
                    }}
                />
                <Typography
                    sx={{
                        color: 'gray',
                        fontSize: '8rem',
                        userSelect: 'none',
                    }}
                >
                    404
                </Typography>
                {isEVisitNotFound && (
                    <Typography
                        sx={{
                            color: 'gray',
                            fontSize: '1rem',
                            userSelect: 'none',
                        }}
                    >
                        {languagepack.roomNotFound}
                    </Typography>
                )}
            </Box>
        </DefaultLayout>
    );
};
export default Error404Page;
