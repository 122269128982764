const en = {
    displayName: 'Your name',
    join: 'Join',
    you: 'You',
    meetingWith: 'Meeting with',
    message: 'Message',
    mute: 'Mute/Unmute',
    hideCamera: 'Turn camera on/off',
    chat: 'Chat',
    leave: 'Leave',
    fullscreen: 'Fullscreen',
    attachment: 'Attachment',
    send: 'Send',
    noMediaDevice: 'No multimedia device available',
    endCall:
        "Thank you for using iGabinet's e-Visit. You can now safely close this browser window",
    backToCall: 'Back to call',
    or: 'Or',
    uploadSizeLimit: 'Attachments can not be larger than 10MB',
    uploadFailed: 'File upload failed',
    noFileUploadsForReservations:
        'Attachments are not available for reservations',
    displayNameRequired: 'Providing your name is required',
    roomNotFound: 'Provided e-Visit has been closed or never existed',
    camera: 'Camera',
    microphone: 'Microphone',
    none: 'None',
    settings: 'Settings',
    unknownDevice: 'Unknown device',
    chatPosition: 'Chat position',
    right: 'Right',
    left: 'Left',
    screenShare: 'screen',
    startScreenShare: 'Share screen',
    stopScreenSharing: 'Stop screen sharing',
    ok: 'Ok',
    cancel: 'Cancel',
    youreAboutToScreenshare: 'Attention: You are about to share your screen',
    screenshareNotice: 'make sure you do not share medical/confidential data',
    dontShowAgain: "don't show again",
    whiteboard: 'Interactive whiteboard',
    noWhiteboardInReservation:
        'The whiteboard is not available for reservation',
    cookiesPopupContent:
        'By using this site, you agree to the use of cookies and local storage.',
};
export default en;
