import {
    apiResponse,
    callRoomDataType,
    fileResourceType,
} from './ApiClient.types';

export const baseApiURL =
    process.env.NODE_ENV === 'production'
        ? 'https://ewizyta.igabinet.com:8100/'
        : 'https://localhost:8100/';

class ApiClient {
    public async getRoomData(uuid: string) {
        const response = await fetch(new URL(`room/${uuid}`, baseApiURL), {
            method: 'GET',
        });

        if (response.ok) {
            const data: apiResponse<callRoomDataType> = await response.json();
            if (data.code === 0) {
                return data.data;
            }
        }

        return null;
    }

    public async uploadFile(roomId: string, file: File) {
        const body = new FormData();
        body.append('room_id', roomId);
        body.append('file', file);

        const response = await fetch(new URL(`file`, baseApiURL), {
            method: 'POST',
            body: body,
        });

        if (response.ok) {
            const data: apiResponse<fileResourceType> = await response.json();
            if (data.code === 0) {
                return data.data;
            }
        }

        return null;
    }

    public async getFileContents(roomId: string, fileId: string) {
        const response = await fetch(
            new URL(`file/${roomId}/${fileId}`, baseApiURL),
            {
                method: 'GET',
            }
        );

        if (response.ok) {
            return await response.blob();
        }

        return null;
    }
}
const apiClient = new ApiClient();
export default apiClient;
