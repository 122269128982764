import { ReactNode, createContext, useContext } from 'react';
import * as uuidGen from 'uuid';

const SelfUserUuidContext = createContext(uuidGen.v4());

export const SelfUserUuidContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const selfUserUuid = useContext(SelfUserUuidContext);

    if (process.env.NODE_ENV === 'development') {
        console.log(`Using self assigned user UUID: ${selfUserUuid}`);
    }

    return (
        <SelfUserUuidContext.Provider value={selfUserUuid}>
            {children}
        </SelfUserUuidContext.Provider>
    );
};

const useSelfUserUuid = () => {
    const selfUserUuid = useContext(SelfUserUuidContext);
    return selfUserUuid;
};
export default useSelfUserUuid;
