import ReactDOM from 'react-dom/client';
import Router from './Router';
import { SettingsContextProvider } from './contexts/SettingsContext';
import { LanguagepackProvider } from './languagepack/Languagepack';
import { SnackbarServiceProvider } from './snackbarService/SnackbarService';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme/theme';
import { SelfUserUuidContextProvider } from './contexts/SelfUserUuidContext';
import { RoomDataContextProvider } from './contexts/RoomDataContext';
import { LocalMediaStreamContextProvider } from './contexts/LocalMediaStreamContext';
import { SelectedDevicesContextProvider } from './contexts/SelectedDevicesContext';
import { WhiteboardSettingsContextProvider } from './contexts/WhiteboardSettingsContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <LanguagepackProvider>
        <SettingsContextProvider>
            <WhiteboardSettingsContextProvider>
                <SelfUserUuidContextProvider>
                    <RoomDataContextProvider>
                        <LocalMediaStreamContextProvider>
                            <SelectedDevicesContextProvider>
                                <ThemeProvider theme={theme}>
                                    <SnackbarServiceProvider>
                                        <Router />
                                    </SnackbarServiceProvider>
                                </ThemeProvider>
                            </SelectedDevicesContextProvider>
                        </LocalMediaStreamContextProvider>
                    </RoomDataContextProvider>
                </SelfUserUuidContextProvider>
            </WhiteboardSettingsContextProvider>
        </SettingsContextProvider>
    </LanguagepackProvider>
);
