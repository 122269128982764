import { Box, Button, Typography } from '@mui/material';
import useLanguagepack from '../languagepack/Languagepack';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../layouts/DefaultLayout';

const EndCallPage = () => {
    const languagepack = useLanguagepack();
    const navigate = useNavigate();

    return (
        <DefaultLayout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <img
                    src="/igabinet_logo.svg"
                    alt="igabinet logo"
                    style={{
                        position: 'absolute',
                        pointerEvents: 'none',
                        opacity: 0.2,
                        width: '40%',
                        zIndex: -1,
                        bottom: 0,
                        right: 0,
                    }}
                />
                <Typography
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {languagepack.endCall}
                </Typography>
                <Typography color="gray">{languagepack.or}</Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    {languagepack.backToCall}
                </Button>
            </Box>
        </DefaultLayout>
    );
};
export default EndCallPage;
