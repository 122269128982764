import { createTheme } from '@mui/material';

import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#007bff',
        },
        secondary: {
            main: '#3e4c59',
        },
        info: {
            main: '#dafbfa',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 8,
    },
    typography: {
        button: {
            fontSize: '0.875rem',
            fontWeight: 600,
            textTransform: 'none',
        },
    },
};

export const theme = createTheme(themeOptions);
