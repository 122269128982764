const pl = {
    displayName: 'Imię i Nazwisko',
    join: 'Dołącz',
    you: 'Ty',
    meetingWith: 'Spotkanie z',
    message: 'Wiadomość',
    mute: 'Wycisz/Odcisz',
    hideCamera: 'Wyłącz kamerę / Włącz kamerę',
    chat: 'Czat',
    leave: 'Opuść spotkanie',
    fullscreen: 'Pełny ekran',
    attachment: 'Załącznik',
    send: 'Wyślij',
    noMediaDevice: 'Brak urządzenia multimedialnego',
    endCall:
        'Dziękujemy za skorzystanie z usług e-Wizyty iGabinet. Mogą państwo bezpiecznie zamknąć to okno przeglądarki.',
    backToCall: 'Powrót do rozmowy',
    or: 'Lub',
    uploadSizeLimit: 'Maksymalny rozmiar załącznika 10MB',
    uploadFailed: 'Przesyłanie pliku nie powiodło się',
    noFileUploadsForReservations:
        'Przesyłanie plików nie jest dostępne dla rezerwacji',
    displayNameRequired: 'Podanie imienia jest wymagane ',
    roomNotFound:
        'Podana e-Wizyta została już zamknięta lub nigdy nie istniała',
    camera: 'Kamera',
    microphone: 'Mikrofon',
    none: 'Brak',
    settings: 'Ustawienia',
    unknownDevice: 'Nieznane urządzenie',
    chatPosition: 'Pozycja chatu',
    right: 'Prawo',
    left: 'Lewo',
    screenShare: 'ekran',
    startScreenShare: 'Udostępnij ekran',
    stopScreenSharing: 'Skończ udostępniać ekran',
    ok: 'Ok',
    cancel: 'Anuluj',
    youreAboutToScreenshare: 'Uwaga: Zamierzasz udostępnić ekran',
    screenshareNotice:
        'upewnij się że nie udostępnisz danych medycznych / objętych tajemnicą',
    dontShowAgain: 'nie pokazuj ponownie',
    whiteboard: 'Tablica inteaktywna',
    noWhiteboardInReservation: 'Tablica nie jest dostępna dla rezerwacji',
    cookiesPopupContent:
        'Korzystając z tej strony, wyrażasz zgodę na wykorzystanie plików cookies oraz local storage.',
};
export default pl;
