/* eslint-disable react-hooks/exhaustive-deps*/
import {
    Box,
    Chip,
    IconButton,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { createRef, useEffect } from 'react';
import MicOffIcon from '@mui/icons-material/MicOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { MetaRequest } from '../wsClient/WsClient.types';
import CameraOffIcon from '@mui/icons-material/NoPhotographyOutlined';
import useLanguagepack from '../languagepack/Languagepack';
import CastOutlinedIcon from '@mui/icons-material/CastOutlined';

const VideoStream = ({
    srcObject,
    mute,
    displayName,
    userMeta,
    isScreenShare,
}: {
    srcObject: MediaStream;
    mute: boolean;
    displayName?: string;
    userMeta?: MetaRequest;
    isScreenShare: boolean;
}) => {
    const videoRef = createRef<HTMLVideoElement>();
    const languagepack = useLanguagepack();
    const theme = useTheme();

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.srcObject = srcObject;

            videoRef.current.play().catch((e) => {
                console.log('play failed - probably media object changed');
            });
        }
    }, [srcObject, videoRef.current]);

    useEffect(() => {
        if (videoRef.current) {
            if (mute) {
                videoRef.current.volume = 0;
                videoRef.current.muted = true;
            } else {
                videoRef.current.volume = 1;
                videoRef.current.muted = false;
            }
        }
    }, [mute, videoRef.current]);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            component="a"
            sx={{
                flex: 1,
                position: 'relative',
                padding: isMobile ? 0 : 1,
                backgroundColor: 'black',
                borderRadius: 2,
                minHeight: 0,
                minWidth: 0,
                width: '100%',
                justifyContent: 'center',
            }}
        >
            {!isScreenShare && !!userMeta?.microphone_muted && (
                <MicOffIcon
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: 'red',
                    }}
                />
            )}

            {(isScreenShare || !!userMeta?.screensharing) && (
                <CastOutlinedIcon
                    sx={{
                        position: 'absolute',
                        right: 40,
                        top: 10,
                        color: 'red',
                    }}
                />
            )}

            {!isScreenShare && !!userMeta?.camera_off && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CameraOffIcon
                        sx={{
                            fontSize: '3rem',
                            color: 'red',
                        }}
                    />
                </Box>
            )}

            <Chip
                label={
                    displayName +
                    (isScreenShare ? ` - ${languagepack.screenShare}` : '')
                }
                sx={{
                    color: 'white',
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    userSelect: 'none',
                }}
            />
            <video
                ref={videoRef}
                autoPlay={true}
                controls={false}
                playsInline={true}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: 10,
                }}
            />
            <Tooltip title={languagepack.fullscreen}>
                <IconButton
                    sx={{
                        color: 'white',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        '&:hover': {
                            transform: 'scale(105%)',
                        },
                    }}
                    onClick={() => {
                        videoRef.current?.requestFullscreen();
                    }}
                >
                    <FullscreenIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
};
export default VideoStream;
