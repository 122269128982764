import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

const SettingsContext = createContext<{
    set: (setting: string, value: string | number | boolean) => void;
    get: (setting: string) => string | number | boolean;
    settings: { [key: string]: string | number | boolean };
} | null>(null);

export const SettingsContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [settings, setSettings] = useState<{
        [key: string]: string | number | boolean;
    }>(JSON.parse(localStorage.getItem('callSettings') || '{}'));

    useEffect(() => {
        localStorage.setItem('callSettings', JSON.stringify(settings));
    }, [settings]);

    return (
        <SettingsContext.Provider
            value={{
                set: (setting: string, value: string | number | boolean) => {
                    setSettings((settings) => ({
                        ...settings,
                        [setting]: value,
                    }));
                },
                get: (setting: string) => {
                    return settings[setting as keyof typeof settings];
                },
                settings: settings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

const useSettingsContext = () => {
    const settingsContext = useContext(SettingsContext);

    return settingsContext;
};
export default useSettingsContext;
