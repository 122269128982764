import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import useLanguagepack from '../languagepack/Languagepack';
import { ReactNode } from 'react';

const Confirm = ({
    open,
    header,
    text,
    onConfirm,
    onCancel,
    additional,
}: {
    open: boolean;
    header: string;
    text: string;
    onConfirm?: VoidFunction;
    onCancel?: VoidFunction;
    additional?: ReactNode;
}) => {
    const languagepack = useLanguagepack();

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <Typography>{text}</Typography>
                {additional}

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                >
                    <Button variant="contained" onClick={onConfirm}>
                        {languagepack.ok}
                    </Button>
                    <Button variant="contained" onClick={onCancel}>
                        {languagepack.cancel}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
export default Confirm;
