import { Alert, Snackbar } from '@mui/material';
import { ReactNode, createContext, useContext, useState } from 'react';

const snackbarContext = createContext<(content: string) => void>(() => {});

export const SnackbarServiceProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [snackbarContent, setSnackbarContent] = useState<string | null>(null);

    const handleClose = () => {
        setSnackbarContent(null);
    };

    const showSnackbar = (content: string) => {
        setSnackbarContent(content);
    };

    return (
        <snackbarContext.Provider value={showSnackbar}>
            <Snackbar
                open={!!snackbarContent}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {snackbarContent}
                </Alert>
            </Snackbar>
            {children}
        </snackbarContext.Provider>
    );
};

const useSnackbar = () => {
    const showSnackbar = useContext(snackbarContext);
    return showSnackbar;
};

export default useSnackbar;
